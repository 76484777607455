<!-- eslint-disable indent -->
<template>
  <div>
    <div class="top-tip-wrapper" v-if="order && !order.hideBillingInformation">
      <div class="top-tip-wrapper-inner">
        <div class="order-info">
          <span class="icon el-icon-bell"></span>
          <span class="content">{{ $t('message.checkout.billingAddressTip', {
            billingAddress: order &&
              order.billingAddress
          }) }}
          </span>
        </div>
      </div>
    </div>
    <div class="checkout-outer-wrapper">
      <div class="checkout-wrapper" v-loading.fullscreen="fullscreenLoading">
        <div v-if="order">
          <!-- 国家 -->
          <div v-if="viewCountryBtn !== false" class="margin-top-20 top-country">
            <div class="country-selector-box">
              <el-select @visible-change="clickCountryHandler" @change="countryChangeHandler"
                class="select-small country-selector" :popper-append-to-body="true" v-model="form.CARD.country"
                filterable autocomplete="false">
                <template slot="prefix">
                  {{ (countryList.find(item => item.code === form.CARD.country) || {}).label }}
                </template>
                <el-option v-for="(c, cindex) in countryList" :key="cindex" :value="c.code"
                  :label="c.label"></el-option>
              </el-select>
            </div>
            <el-tooltip class="gateway-light-tooltip" effect="light" popper-class="gateway-light-tooltip-popper">
              <div slot="content">{{ $t('message.checkout.changeCountryTip') }}</div>
              <el-button type="text">
                <img src="../../assets/images/tooltip.png" width="16" height="17" />
              </el-button>
            </el-tooltip>
          </div>
          <div class="payment-wrapper">
            <div class="payment-content-wrapper">
              <div class="payment-content order-box margin-top-20">
                <div class="title">{{ $t('message.checkout.paymentMethod') }}</div>
                <div class="payment-method-wrapper">
                  <el-collapse accordion v-model="activePaymentMethod">
                    <el-collapse-item v-for="(item, index) in payMethods" :key="index" :name="index" :disabled="true"
                      class="method-collapse-item" :class="{
                        'before': Number.isInteger(activePaymentMethod) && activePaymentMethod === index + 1,
                        'after': Number.isInteger(activePaymentMethod) && activePaymentMethod === index - 1,
                      }">
                      <template slot="title">
                        <div class="padding-left-3 method-title">
                          <el-radio v-model="activePaymentMethod" :label="index" @change="methodChange"
                            class="card-type-radio">
                            <div class="card-name">
                              <!-- 左侧LOGO -->
                              <span class="card-img-wrapper">
                                <img v-if="item.cardName === 'CARD'" class="card-img"
                                  src="../../assets/images/cards/Credit.png" />
                                <img v-else-if="['Alipay+', 'Konbini'].includes(item.cardName)" class="card-img"
                                  :src="item.logoUrl" />
                                <img v-else class="card-img"
                                  :src="item.cardTypeInfo && item.cardTypeInfo.length && item.cardTypeInfo[0].logoPath" />
                              </span>
                              <span class="card-type-name" v-if="item.cardTypeName === 'DANA_VA'">Virtual Account</span>
                              <span class="card-type-name" v-else-if="item.cardTypeName === 'DANA_Checkout'">DANA</span>
                              <span class="card-type-name" v-else>{{ item.cardTypeName }}</span>

                            </div>
                          </el-radio>
                          <!-- CARD -->
                          <div class="payments-support"
                            v-if="['CARD'].includes(item.cardName) && item.cardTypeInfo && item.cardTypeInfo.length && payMethods[activePaymentMethod].cardName !== 'CARD'">
                            <template v-for="(cardItem, idx) of supportCardTypes">
                              <el-tooltip v-if="idx < 3" :key="idx" effect="dark" :content="cardItem.cardTypeName">
                                <img class="img" :src="cardItem.logoPath" height="20" />
                              </el-tooltip>
                            </template>
                            <!-- 右边logo多于3个 -->
                            <template v-if="supportCardTypes.length > 3">
                              <span class="card-number">+{{ supportCardTypes.length - 3 }}</span>
                            </template>
                          </div>
                          <!-- 非CARD -->
                          <div class="payments-support"
                            v-if="['Alipay+'].includes(item.cardName) && item.cardTypeInfo && item.cardTypeInfo.length">
                            <template v-for="(cardItem, idx) of item.cardTypeInfo">
                              <el-tooltip v-if="idx < 3" :key="idx" effect="dark" :content="cardItem.cardTypeName">
                                <img class="img" :src="cardItem.logoPath" height="20" />
                              </el-tooltip>
                            </template>
                            <!-- 右边logo多于3个 -->
                            <template v-if="item.cardTypeInfo && item.cardTypeInfo.length > 3">
                              <span class="card-number">+{{ item.cardTypeInfo.length - 3 }}</span>
                            </template>
                          </div>
                          <!-- ALIPAY LOGO -->
                          <!-- <div class="payments-support" v-if="ALIPAY.includes(item.cardName)">
                      <el-tooltip effect="dark" :content="item.cardName">
                        <img class="img" src="../../assets/images/alipay/alipay.png" height="20" />
                      </el-tooltip>
                    </div> -->
                        </div>
                      </template>
                      <div class="payment-contents">
                        <!-- HERE is DANA！ 通过cardName判断 DANA_VA-->
                        <div class="DANA_wrap padding-left-10 padding-right-10 mobile-padding"
                          v-if="item.cardName === 'DANA_VA'">
                          <div class="DANA_item" :class="selectedBankCode === item.code ? 'DANA_active' : ''"
                            v-for="(item, index) in DANA_BANK_OPTIONS" :key=index @click="selectBank(item.code)">
                            <img :src="item.image" alt="">
                          </div>
                        </div>
                        <!-- TODO:后续拆解为共性组件 -->
                        <div class="DANA_wrap padding-left-10 padding-right-10 mobile-padding"
                          v-if="item.cardName === 'DOKU_VA'">
                          <div class="DANA_item" :class="selectedBankCode === item.code ? 'DANA_active' : ''"
                            v-for="(item, index) in DOKU_BANK_OPTIONS" :key=index @click="selectBank(item.code)">
                            <img :src="item.image" alt="">
                          </div>
                        </div>
                        <el-form label-position="top" :model="item.cardName === 'CARD' ? form.CARD : form.LOCAL"
                          :rules="(item.children && item.children.length) ? (activeChildPaymentMethod === '' ? {} : item.children[activeChildPaymentMethod].rules) : item.rules"
                          ref="formRef">
                          <div class="padding-left-10 padding-right-10 mobile-padding">
                            <!-- 支付信息 -->
                            <el-row :gutter="device.device === 'WAP' ? 5 : 30" class="fields-row">
                              <template v-for="(paymentField, paymentIndex) in item.paymentConfigurationFields">
                                <template v-if="paymentField.name === 'cardNum'">
                                  <el-col :key="paymentIndex" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                                    <el-form-item prop="cardNum" :label="$t('message.checkout.cardNumber')"
                                      :ref="`cardNumRef${refSuffix[paymentType]}`">
                                      <div>
                                        <div><card-number :value.sync="form[paymentType].cardNum"
                                            :support-cards="item && item.cardTypeInfo" @blur="cardNumberBlur"
                                            @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                            @change="sendPaymentInfoInputEvent(paymentField.name)"></card-number></div>
                                        <template v-if="item.cardTypeInfo">
                                          <div style="display: flex;">
                                            <template v-for="(cardImg, cardImgIdx) in supportCardTypes">
                                              <el-tooltip :key="cardImgIdx" effect="dark"
                                                :content="cardImg.cardTypeName"
                                                style="margin-right:8px;margin-top: 5px;">
                                                <img :key="cardImgIdx" :src="cardImg.logoPath" height="16"
                                                  style="width:auto;" />
                                              </el-tooltip>
                                            </template>
                                          </div>
                                        </template>
                                      </div>
                                    </el-form-item>
                                  </el-col>
                                </template>
                                <template v-else-if="!['cardNum', 'jpLastName'].includes(paymentField.name)">
                                  <el-col :class="paymentField.name"
                                    v-if="paymentField.name != 'cardHolderName' || (paymentField.name === 'cardHolderName' && !hideCardHolderName)"
                                    :key="paymentIndex"
                                    :xs="['effectiveDate', 'cvv'].includes(paymentField.name) ? 12 : 24" :sm="12"
                                    :md="12" :lg="12" :xl="12">
                                    <el-form-item v-if="paymentField.name === 'effectiveDate'"
                                      :label="$t('message.checkout.expiredDate')" prop="effectiveDate"
                                      :ref="`effectiveDateRef${refSuffix[paymentType]}`"
                                      :style="{ 'margin-bottom': marginBottom }">
                                      <el-input maxlength="5" placeholder="MM/YY" autocomplete="cc-exp"
                                        v-model="form[paymentType].effectiveDate"
                                        @input.native="effectiveDateChangeHandler" @blur="formInlineItemBlurHandler"
                                        @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                        @change="sendPaymentInfoInputEvent(paymentField.name)" />
                                    </el-form-item>
                                    <el-form-item v-if="paymentField.name === 'cvv'" :label="$t('message.checkout.cvv')"
                                      prop="cvv" :ref="`cvvRef${refSuffix[paymentType]}`"
                                      :style="{ 'margin-bottom': marginBottom }">
                                      <el-input class="cvv" inputmode="numeric" maxlength="4" autocomplete="cc-csc"
                                        v-model="form[paymentType].cvv" @input="cvvChangeHandler"
                                        @blur="formInlineItemBlurHandler"
                                        @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                        @change="sendPaymentInfoInputEvent(paymentField.name)">
                                        <template slot="suffix">
                                          <div class="img-cvv-box">
                                            <el-popover placement="bottom-end" trigger="hover" :visible-arrow="false"
                                              :offset="5" popper-class="cvv-popover">
                                              <img slot="reference" class="img-cvv"
                                                src="@/assets/images/icon-cvv.png" />
                                              <div>
                                                <div class="cvv-content-top">
                                                  <img src="@/assets/images/icon-cvv1.png" />
                                                  <img src="@/assets/images/icon-cvv2.png" />
                                                </div>
                                                <div class="cvv-content-bottom">{{ $t('message.checkout.cvvTips') }}
                                                </div>
                                              </div>
                                            </el-popover>
                                          </div>
                                        </template>
                                      </el-input>
                                    </el-form-item>
                                    <el-form-item v-if="paymentField.name === 'cardHolderName'"
                                      :label="$t('message.checkout.holderName')" prop="cardHolderName"
                                      :ref="`cardHolderNameRef${refSuffix[paymentType]}`">
                                      <el-input maxlength="50" autocomplete="cc-name"
                                        v-model="form[paymentType].cardHolderName"
                                        :placeholder="$t('message.checkout.holderNameTips')"
                                        @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                        @change="sendPaymentInfoInputEvent(paymentField.name)" />
                                    </el-form-item>
                                    <el-form-item v-if="paymentField.name === 'walletAccountName'"
                                      :label="$t('message.checkout.walletAccountName')" prop="walletAccountName"
                                      :ref="`walletAccountNameRef${refSuffix[paymentType]}`">
                                      <el-input maxlength="50" autocomplete="off"
                                        v-model="form[paymentType].walletAccountName"
                                        @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                        @change="sendPaymentInfoInputEvent(paymentField.name)" />
                                    </el-form-item>
                                    <el-form-item v-if="paymentField.name === 'prepaidNumber'"
                                      :label="$t('message.checkout.cardNumber')" prop="prepaidNumber"
                                      :ref="`prepaidNumber${refSuffix[paymentType]}`">
                                      <el-input maxlength="16" autocomplete="off"
                                        v-model="form[paymentType].prepaidNumber" inputmode="numeric"
                                        @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                        @change="sendPaymentInfoInputEvent(paymentField.name)" />
                                    </el-form-item>
                                    <el-form-item v-if="paymentField.name === 'blikcode'"
                                      :label="$t('message.checkout.blikcode')" prop="blikcode"
                                      :ref="`blikcode${refSuffix[paymentType]}`">
                                      <el-input autocomplete="off" v-model="form[paymentType].blikcode" :maxlength="6"
                                        @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                        @change="sendPaymentInfoInputEvent(paymentField.name)"></el-input>
                                    </el-form-item>
                                    <el-form-item v-if="paymentField.name === 'identityNumber'"
                                      :label="identityNumberFormLabel" prop="identityNumber"
                                      :ref="`identityNumberRef${refSuffix[paymentType]}`">
                                      <el-input maxlength="64" autocomplete="off"
                                        v-model="form[paymentType].identityNumber" @input="onIdentityNumberInput"
                                        @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                        @change="sendPaymentInfoInputEvent(paymentField.name)" />
                                    </el-form-item>
                                    <el-form-item v-if="paymentField.name === 'birthDate'"
                                      @input.native="birthDateChangeHandler" :label="$t('message.checkout.birthDate')"
                                      prop="birthDate" :ref="`birthDateRef${refSuffix[paymentType]}`">
                                      <el-input maxlength="10" autocomplete="off" v-model="form[paymentType].birthDate"
                                        placeholder="YYYY/MM/DD" @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                        @change="sendPaymentInfoInputEvent(paymentField.name)" />
                                    </el-form-item>
                                    <el-form-item v-if="paymentField.name === 'issueDate'"
                                      :label="$t('message.checkout.issueDate')" prop="issueDate"
                                      :ref="`issueDateRef${refSuffix[paymentType]}`"
                                      :style="{ 'margin-bottom': marginBottom }">
                                      <el-input maxlength="5" placeholder="MM/YY" autocomplete="off"
                                        v-model="form[paymentType].issueDate" @input.native="issueDateChangeHandler"
                                        @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                        @change="sendPaymentInfoInputEvent(paymentField.name)" />
                                    </el-form-item>
                                    <el-form-item v-if="paymentField.name === 'bankName'"
                                      :label="$t('message.checkout.bankName')" prop="bankName"
                                      :ref="`bankNameRef${refSuffix[paymentType]}`">
                                      <el-select filterable v-model="form[paymentType].bankName"
                                        @visible-change="clickSelectHandler($event, 'payment', paymentField.name)"
                                        @change="sendPaymentInfoInputEvent(paymentField.name)">
                                        <el-option v-for="(bank, bankIndex) in BANK_OPTIONS[item.cardName]"
                                          :key="bankIndex" :value="bank.code" :label="bank.name"></el-option>
                                      </el-select>
                                    </el-form-item>
                                    <el-form-item v-if="paymentField.name === 'iBan'"
                                      :label="$t('message.checkout.iBan')" prop="iBan"
                                      :ref="`iBanRef${refSuffix[paymentType]}`">
                                      <el-input maxlength="64" autocomplete="off" v-model="form[paymentType].iBan"
                                        @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                        @change="sendPaymentInfoInputEvent(paymentField.name)" />
                                    </el-form-item>
                                    <el-row :gutter="device.device === 'WAP' ? 5 : 10"
                                      v-if="paymentField.name === 'jpFirstName'">
                                      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                        <el-form-item label="姓/カタカナ" prop="jpFirstName"
                                          :ref="`jpFirstNameRef${refSuffix[paymentType]}`" class="nowrap-text">
                                          <el-input maxlength="64" autocomplete="off"
                                            v-model="form[paymentType].jpFirstName" placeholder="姓/カタカナ"
                                            @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                            @change="sendPaymentInfoInputEvent(paymentField.name)" />
                                        </el-form-item>
                                      </el-col>
                                      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                        <el-form-item label="名/カタカナ" prop="jpLastName"
                                          :ref="`jpLastNameRef${refSuffix[paymentType]}`" class="margin-top-0">
                                          <el-input maxlength="64" autocomplete="off"
                                            v-model="form[paymentType].jpLastName" placeholder="名/カタカナ"
                                            @focus="sendPaymentInfoFocusEvent('jpLastName')"
                                            @change="sendPaymentInfoInputEvent('jpLastName')" />
                                        </el-form-item>
                                      </el-col>
                                    </el-row>
                                  </el-col>
                                </template>
                              </template>
                            </el-row>
                            <el-row :gutter="device.device === 'WAP' ? 5 : 30" class="fields-row"
                              v-if="item.cardName === 'CARD' && order.canInstallment">
                              <!-- 分期 -->
                              <el-col :sm="24" :md="12" :lg="12" :xl="12">
                                <el-form-item prop="periodValue" :label="$t('message.checkout.intallment')">
                                  <el-select v-model="form.CARD.periodValue" :disabled="instalmentDisabled"
                                    :placeholder="$t('message.checkout.intallment')" popper-class="loading-select"
                                    @focus="getInstalmentList" filterable autocomplete="off" auto-complete="off"
                                    @visible-change="clickSelectHandler($event, 'billing', 'periodValue')"
                                    @change="sendBillingInfoInputEvent('periodValue')">
                                    <template v-if="!instalmentLoading && instalmentInfo && instalmentInfo.stages">
                                      <template v-for="(instalmentItem, index) in instalmentInfo.stages">
                                        <el-option v-if="instalmentItem.stageNumber === '1'" :key="index"
                                          :value="instalmentItem.stageNumber"
                                          :label="`${$t('message.checkout.payInFull')}`"></el-option>
                                        <el-option v-else :key="index" :value="instalmentItem.stageNumber"
                                          :label="`${$t('message.checkout.payIn', { count: instalmentItem.stageNumber })} ${instalmentInfo.institution === 'DLOCAL' ? `- ${instalmentItem.stageName}` : ''}`">
                                        </el-option>
                                      </template>
                                    </template>
                                    <el-option v-if="instalmentLoading" :key="'loading'" label="加载中..." disabled
                                      value="loading" class="select-loading-item">
                                      <span class="iconfont el-icon-loading"></span>
                                    </el-option>
                                  </el-select>
                                  <p v-if="!['', '1'].includes(form.CARD.periodValue)" class="intallment-tip">{{
                                    $t('message.checkout.intallmentTip') }}</p>
                                </el-form-item>
                              </el-col>
                            </el-row>
                          </div>
                          <!-- 子支付方式 -->
                          <div v-if="item.children && item.children.length" class="sub-payment-method">
                            <el-collapse accordion v-model="activeChildPaymentMethod">
                              <template v-for="(childPayMethod, childIdx) in item.children">
                                <el-collapse-item :key="childIdx" :name="childIdx" :disabled="true"
                                  class="sub-method-collapse-item" :class="{ first: childIdx === 0 }">
                                  <template slot="title">
                                    <div class="sub-method-title">
                                      <el-radio v-model="activeChildPaymentMethod" :label="childIdx"
                                        @change="subMethodChange" class="sub-method-radio">
                                        <span class="sub-method-img-wrapper">
                                          <img
                                            :src="childPayMethod.cardTypeInfo && childPayMethod.cardTypeInfo.length && childPayMethod.cardTypeInfo[0].logoPath" />
                                        </span>
                                        <span class="sub-method-name">{{ childPayMethod.cardTypeName }}</span>
                                      </el-radio>
                                    </div>
                                  </template>
                                  <!-- 子支付方式 支付信息 -->
                                  <div>
                                    <el-row :gutter="device.device === 'WAP' ? 5 : 30"
                                      v-if="childPayMethod.paymentConfigurationFields && childPayMethod.paymentConfigurationFields.length">
                                      <template
                                        v-for="(paymentField, paymentIndex) in childPayMethod.paymentConfigurationFields">
                                        <template v-if="paymentField.name === 'cardNum'">
                                          <el-col :key="paymentIndex" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                                            <el-form-item prop="cardNum" :label="$t('message.checkout.cardNumber')"
                                              :ref="`cardNumRef${childIdx}`">
                                              <div>
                                                <div><card-number :value.sync="form[paymentType].cardNum"
                                                    :support-cards="payMethods[1].cardTypeInfo" @blur="cardNumberBlur"
                                                    @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                                    @change="sendPaymentInfoInputEvent(paymentField.name)"></card-number>
                                                </div>
                                                <template v-if="childPayMethod.cardTypeInfo">
                                                  <div style="display: flex;">
                                                    <template v-for="(cardImg, cardImgIdx) in supportCardTypes">
                                                      <el-tooltip :key="cardImgIdx" effect="dark"
                                                        :content="cardImg.cardTypeName"
                                                        style="margin-right:8px;margin-top: 5px;">
                                                        <img :key="cardImgIdx" :src="cardImg.logoPath" height="16"
                                                          style="width:auto;" />
                                                      </el-tooltip>
                                                    </template>
                                                  </div>
                                                </template>
                                              </div>
                                            </el-form-item>
                                          </el-col>
                                        </template>
                                        <template v-else-if="!['cardNum', 'jpLastName'].includes(paymentField.name)">
                                          <el-col
                                            v-if="paymentField.name != 'cardHolderName' || (paymentField.name === 'cardHolderName' && !hideCardHolderName)"
                                            :key="paymentIndex"
                                            :xs="['effectiveDate', 'cvv'].includes(paymentField.name) ? 12 : 24"
                                            :sm="12" :md="12" :lg="12" :xl="12">
                                            <el-form-item v-if="paymentField.name === 'effectiveDate'"
                                              :label="$t('message.checkout.expiredDate')" prop="effectiveDate"
                                              :ref="`effectiveDateRef${childIdx}`"
                                              :style="{ 'margin-bottom': marginBottom }">
                                              <el-input maxlength="5" placeholder="MM/YY" autocomplete="cc-exp"
                                                v-model="form[paymentType].effectiveDate"
                                                @input.native="effectiveDateChangeHandler"
                                                @blur="formInlineItemBlurHandler"
                                                @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                                @change="sendPaymentInfoInputEvent(paymentField.name)" />
                                            </el-form-item>
                                            <el-form-item v-if="paymentField.name === 'cvv'"
                                              :label="$t('message.checkout.cvv')" prop="cvv" :ref="`cvvRef${childIdx}`"
                                              :style="{ 'margin-bottom': marginBottom }">
                                              <el-input class="cvv" inputmode="numeric" maxlength="4"
                                                autocomplete="cc-csc" v-model="form[paymentType].cvv"
                                                @input="cvvChangeHandler" @blur="formInlineItemBlurHandler"
                                                @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                                @change="sendPaymentInfoInputEvent(paymentField.name)">
                                                <template slot="suffix">
                                                  <div class="img-cvv-box">
                                                    <el-popover placement="bottom-end" trigger="hover"
                                                      :visible-arrow="false" :offset="5" popper-class="cvv-popover">
                                                      <img slot="reference" class="img-cvv"
                                                        src="@/assets/images/icon-cvv.png" />
                                                      <div>
                                                        <div class="cvv-content-top">
                                                          <img src="@/assets/images/icon-cvv1.png" />
                                                          <img src="@/assets/images/icon-cvv2.png" />
                                                        </div>
                                                        <div class="cvv-content-bottom">
                                                          {{ $t('message.checkout.cvvTips') }}</div>
                                                      </div>
                                                    </el-popover>
                                                  </div>
                                                </template>
                                              </el-input>
                                            </el-form-item>
                                            <el-form-item
                                              v-if="paymentField.name === 'cardHolderName' && !hideCardHolderName"
                                              :label="$t('message.checkout.holderName')" prop="cardHolderName"
                                              :ref="`cardHolderNameRef${childIdx}`">
                                              <el-input maxlength="50" autocomplete="cc-name"
                                                v-model="form[paymentType].cardHolderName"
                                                :placeholder="$t('message.checkout.holderNameTips')"
                                                @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                                @change="sendPaymentInfoInputEvent(paymentField.name)" />
                                            </el-form-item>
                                            <el-form-item v-if="paymentField.name === 'walletAccountName'"
                                              :label="$t('message.checkout.walletAccountName')" prop="walletAccountName"
                                              :ref="`walletAccountNameRef${childIdx}`">
                                              <el-input maxlength="50" autocomplete="off"
                                                v-model="form[paymentType].walletAccountName"
                                                @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                                @change="sendPaymentInfoInputEvent(paymentField.name)" />
                                            </el-form-item>
                                            <el-form-item v-if="paymentField.name === 'prepaidNumber'"
                                              :label="$t('message.checkout.cardNumber')" prop="prepaidNumber"
                                              :ref="`prepaidNumber${childIdx}`">
                                              <el-input maxlength="16" autocomplete="off"
                                                v-model="form[paymentType].prepaidNumber" inputmode="numeric"
                                                @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                                @change="sendPaymentInfoInputEvent(paymentField.name)" />
                                            </el-form-item>
                                            <el-form-item v-if="paymentField.name === 'blikcode'"
                                              :label="$t('message.checkout.blikcode')" prop="blikcode"
                                              :ref="`blikcode${childIdx}`">
                                              <el-input autocomplete="off" v-model="form[paymentType].blikcode"
                                                :maxlength="6" @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                                @change="sendPaymentInfoInputEvent(paymentField.name)"></el-input>
                                            </el-form-item>
                                            <el-form-item v-if="paymentField.name === 'identityNumber'"
                                              :label="identityNumberFormLabel" prop="identityNumber"
                                              :ref="`identityNumberRef${childIdx}`">
                                              <el-input maxlength="64" autocomplete="off"
                                                v-model="form[paymentType].identityNumber"
                                                @input="onIdentityNumberInput"
                                                @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                                @change="sendPaymentInfoInputEvent(paymentField.name)" />
                                            </el-form-item>
                                            <el-form-item v-if="paymentField.name === 'birthDate'"
                                              @input.native="birthDateChangeHandler"
                                              :label="$t('message.checkout.birthDate')" prop="birthDate"
                                              :ref="`birthDateRef${childIdx}`">
                                              <el-input maxlength="10" autocomplete="off"
                                                v-model="form[paymentType].birthDate" placeholder="YYYY/MM/DD"
                                                @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                                @change="sendPaymentInfoInputEvent(paymentField.name)" />
                                            </el-form-item>
                                            <el-form-item v-if="paymentField.name === 'bankName'"
                                              :label="$t('message.checkout.bankName')" prop="bankName"
                                              :ref="`bankNameRef${childIdx}`">
                                              <el-select filterable v-model="form[paymentType].bankName"
                                                @visible-change="clickSelectHandler($event, 'payment', paymentField.name)"
                                                @change="sendPaymentInfoInputEvent(paymentField.name)">
                                                <el-option v-for="(bank, bankIndex) in BANK_OPTIONS[item.cardName]"
                                                  :key="bankIndex" :value="bank.code" :label="bank.name"></el-option>
                                              </el-select>
                                            </el-form-item>
                                            <el-form-item v-if="paymentField.name === 'iBan'"
                                              :label="$t('message.checkout.iBan')" prop="iBan"
                                              :ref="`iBanRef${childIdx}`">
                                              <el-input maxlength="64" autocomplete="off"
                                                v-model="form[paymentType].iBan"
                                                @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                                @change="sendPaymentInfoInputEvent(paymentField.name)" />
                                            </el-form-item>
                                            <el-row :gutter="device.device === 'WAP' ? 5 : 10"
                                              v-if="paymentField.name === 'jpFirstName'">
                                              <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                                <el-form-item label="姓/カタカナ" prop="jpFirstName"
                                                  :ref="`jpFirstNameRef${childIdx}`" class="nowrap-text">
                                                  <el-input maxlength="64" autocomplete="off"
                                                    v-model="form[paymentType].jpFirstName" placeholder="姓/カタカナ"
                                                    @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                                    @change="sendPaymentInfoInputEvent(paymentField.name)" />
                                                </el-form-item>
                                              </el-col>
                                              <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                                <el-form-item label="名/カタカナ" prop="jpLastName"
                                                  :ref="`jpLastNameRef${childIdx}`" class="margin-top-0">
                                                  <el-input maxlength="64" autocomplete="off"
                                                    v-model="form[paymentType].jpLastName" placeholder="名/カタカナ"
                                                    @focus="sendPaymentInfoFocusEvent('jpLastName')"
                                                    @change="sendPaymentInfoInputEvent('jpLastName')" />
                                                </el-form-item>
                                              </el-col>
                                            </el-row>
                                          </el-col>
                                        </template>
                                      </template>
                                    </el-row>
                                  </div>
                                  <div>
                                    <div
                                      v-if="childPayMethod.billConfigurationFields && childPayMethod.billConfigurationFields.length">
                                      <div class="billing-title">{{ $t('message.checkout.billingAddress') }}</div>
                                      <div class="margin-top-10">
                                        <el-row :gutter="device.device === 'WAP' ? 5 : 30" class="billing-row">
                                          <template
                                            v-for="(field, fieldIndex) in childPayMethod.billConfigurationFields">
                                            <!-- 子支付方式只有非本地支付存在: 账单地址 -->
                                            <!-- 国家 -->
                                            <el-col v-if="field.name === 'country'" :key="fieldIndex" :xs="24" :sm="12"
                                              :md="12" :lg="12" :xl="12">
                                              <el-form-item :label="$t('message.checkout.country')" prop="country"
                                                :ref="`countryRef${childIdx}`">
                                                <el-select disabled filterable v-model="form.LOCAL.country">
                                                  <el-option v-for="(c, cindex) in COUNTRY_OPTIONS" :key="cindex"
                                                    :value="c.code"
                                                    :label="$t(`message.country.${c.code}`)"></el-option>
                                                </el-select>
                                              </el-form-item>
                                            </el-col>
                                            <!-- 州 -->
                                            <el-col v-if="field.name === 'province'" :key="fieldIndex" :xs="24" :sm="12"
                                              :md="12" :lg="12" :xl="12">
                                              <el-form-item :label="$t('message.checkout.province')" prop="province"
                                                :ref="`provinceRef${childIdx}`">
                                                <!-- 国家为【US/CA/CN】时为select,其它为input -->
                                                <el-select v-if="selectStateCountry.includes(form.LOCAL.country)"
                                                  filterable v-model="form.LOCAL.province"
                                                  @visible-change="clickSelectHandler($event, 'billing', field.name)"
                                                  @change="sendBillingInfoInputEvent(field.name)">
                                                  <el-option v-for="(s, sindex) in STATES_OPTIONS[form.LOCAL.country]"
                                                    :key="sindex" :value="s.value"
                                                    :label="$t(`message.state.${form.LOCAL.country}.${s.value}`)"></el-option>
                                                </el-select>
                                                <el-input v-else maxlength="64" v-model="form.LOCAL.province"
                                                  autocomplete="address-level1"
                                                  @focus="sendBillingInfoFocusEvent(field.name)"
                                                  @change="sendBillingInfoInputEvent(field.name)" />
                                              </el-form-item>
                                            </el-col>
                                            <!-- Address -->
                                            <el-col v-if="field.name === 'address'" :key="fieldIndex" :xs="24" :sm="12"
                                              :md="12" :lg="12" :xl="12">
                                              <el-form-item :label="$t('message.checkout.address')" prop="address"
                                                :ref="`addressRef${childIdx}`">
                                                <el-input maxlength="256" autocomplete="street-address"
                                                  v-model="form.LOCAL.address"
                                                  @focus="sendBillingInfoFocusEvent(field.name)"
                                                  @change="sendBillingInfoInputEvent(field.name)" />
                                              </el-form-item>
                                            </el-col>
                                            <!-- Town/City -->
                                            <el-col v-if="field.name === 'city'" :key="fieldIndex" :xs="24" :sm="12"
                                              :md="12" :lg="12" :xl="12">
                                              <el-form-item :label="$t('message.checkout.city')" prop="city"
                                                :ref="`cityRef${childIdx}`">
                                                <el-input maxlength="64" autocomplete="off" v-model="form.LOCAL.city"
                                                  @focus="sendBillingInfoFocusEvent(field.name)"
                                                  @change="sendBillingInfoInputEvent(field.name)" />
                                              </el-form-item>
                                            </el-col>
                                            <!-- 邮编 -->
                                            <el-col v-if="field.name === 'postalCode'" :key="fieldIndex" :xs="24"
                                              :sm="12" :md="12" :lg="12" :xl="12">
                                              <el-form-item :label="$t('message.checkout.postalCode')" prop="postalCode"
                                                :ref="`postalCodeRef${childIdx}`">
                                                <el-input maxlength="32" autocomplete="postal-code"
                                                  v-model="form.LOCAL.postalCode" @input="onPostalCodeInput"
                                                  @focus="sendBillingInfoFocusEvent(field.name)"
                                                  @change="sendBillingInfoInputEvent(field.name)" />
                                              </el-form-item>
                                            </el-col>
                                            <!-- 邮箱 -->
                                            <el-col v-if="field.name === 'email'" :key="fieldIndex" :xs="24" :sm="12"
                                              :md="12" :lg="12" :xl="12">
                                              <el-form-item :label="$t('message.checkout.email')" prop="email"
                                                :ref="`emailRef${childIdx}`">
                                                <el-autocomplete :maxlength="256" autocomplete="email"
                                                  v-model="form.LOCAL.email" :trigger-on-focus="false"
                                                  :fetch-suggestions="queryEmailSearch"
                                                  @focus="sendBillingInfoFocusEvent(field.name)"
                                                  @change="sendBillingInfoInputEvent(field.name)" />
                                              </el-form-item>
                                            </el-col>
                                            <!-- Contact Name -->
                                            <el-col v-if="field.name === 'firstName'" :key="fieldIndex" :xs="24"
                                              :sm="12" :md="12" :lg="12" :xl="12">
                                              <el-row :gutter="device.device === 'WAP' ? 5 : 10">
                                                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                                  <el-form-item
                                                    :label="lang === 'ja' ? $t('message.checkout.firstName') : $t('message.checkout.contactName')"
                                                    prop="firstName" :ref="`firstNameRef${childIdx}`"
                                                    class="nowrap-text">
                                                    <el-input maxlength="64" autocomplete="off"
                                                      v-model="form.LOCAL.firstName"
                                                      :placeholder="$t('message.checkout.firstName')"
                                                      @focus="sendBillingInfoFocusEvent(field.name)"
                                                      @change="sendBillingInfoInputEvent(field.name)" />
                                                  </el-form-item>
                                                </el-col>
                                                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                                  <el-form-item
                                                    :label="lang === 'ja' ? $t('message.checkout.lastName') : ''"
                                                    prop="lastName" :ref="`lastNameRef${childIdx}`"
                                                    :class="{ 'margin-top-23': lang !== 'ja' }">
                                                    <el-input maxlength="64" autocomplete="off"
                                                      v-model="form.LOCAL.lastName"
                                                      :placeholder="$t('message.checkout.lastName')"
                                                      @focus="sendBillingInfoFocusEvent('lastName')"
                                                      @change="sendBillingInfoInputEvent('lastName')" />
                                                  </el-form-item>
                                                </el-col>
                                              </el-row>
                                            </el-col>
                                            <!-- 手机号码 -->
                                            <el-col v-if="field.name === 'phone'" :key="fieldIndex" :xs="24" :sm="12"
                                              :md="12" :lg="12" :xl="12">
                                              <el-form-item :label="$t('message.checkout.phone')" prop="phone"
                                                :ref="`phoneRef${childIdx}`">
                                                <el-input maxlength="32" autocomplete="off" v-model="form.LOCAL.phone"
                                                  @focus="sendBillingInfoFocusEvent(field.name)"
                                                  @change="sendBillingInfoInputEvent(field.name)" />
                                              </el-form-item>
                                            </el-col>
                                            <!-- Street -->
                                            <el-col v-if="field.name === 'street'" :key="fieldIndex" :xs="24" :sm="12"
                                              :md="12" :lg="12" :xl="12">
                                              <el-form-item :label="$t('message.checkout.street')" prop="street"
                                                :ref="`streetRef${childIdx}`">
                                                <el-input maxlength="64" autocomplete="off" v-model="form.LOCAL.street"
                                                  @focus="sendBillingInfoFocusEvent(field.name)"
                                                  @change="sendBillingInfoInputEvent(field.name)" />
                                              </el-form-item>
                                            </el-col>
                                            <!-- 门牌号 -->
                                            <el-col v-if="field.name === 'number'" :key="fieldIndex" :xs="24" :sm="12"
                                              :md="12" :lg="12" :xl="12">
                                              <el-form-item :label="$t('message.checkout.number')" prop="number"
                                                :ref="`numberRef${childIdx}`">
                                                <el-input maxlength="64" autocomplete="off" v-model="form.LOCAL.number"
                                                  @focus="sendBillingInfoFocusEvent(field.name)"
                                                  @change="sendBillingInfoInputEvent(field.name)" />
                                              </el-form-item>
                                            </el-col>
                                          </template>
                                        </el-row>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="btns" style="padding: 0;">
                                    <div class="policy-text pc">
                                      <span>{{ $t('message.checkout.policy.prefix') }}</span>
                                      <el-button type="text" @click="viewPolicy">{{ $t('message.checkout.privacy')
                                        }}</el-button>
                                      <span>{{ $t('message.checkout.policy.suffix') }}</span>
                                    </div>
                                    <el-button @click="submit" type="primary" class="btn-submit margin-top-12">
                                      {{ $t('message.checkout.pay') }} {{ order && order.txnCurrency }} {{ order &&
                                        order.txnAmount }}
                                    </el-button>
                                  </div>
                                </el-collapse-item>
                              </template>
                            </el-collapse>
                          </div>
                          <!-- 没有子支付方式的账单地址 -->
                          <div v-if="item.billConfigurationFields && item.billConfigurationFields.length">
                            <div class="billing-title padding-left-10 padding-right-10 margin-top-5 mobile-padding">
                              {{ $t('message.checkout.billingAddress') }}</div>
                            <div class="padding-left-10 padding-right-10 margin-top-20 mobile-padding">
                              <el-row :gutter="device.device === 'WAP' ? 5 : 30" class="billing-row">
                                <template v-for="(field, fieldIndex) in item.billConfigurationFields">
                                  <!-- 卡支付账单地址 -->
                                  <template v-if="item.cardName === 'CARD'">
                                    <!-- 国家 -->
                                    <el-col v-if="field.name === 'country'" :key="fieldIndex" :xs="24" :sm="12" :md="12"
                                      :lg="12" :xl="12">
                                      <el-form-item :label="$t('message.checkout.country')" prop="country"
                                        ref="countryRefCard">
                                        <el-select filterable v-model="form.CARD.country" autocomplete="new-password"
                                          auto-complete="new-password" disabled>
                                          <el-option v-for="(c, cindex) in COUNTRY_OPTIONS" :key="cindex"
                                            :value="c.code" :label="$t(`message.country.${c.code}`)"></el-option>
                                        </el-select>
                                      </el-form-item>
                                    </el-col>
                                    <!-- 州 -->
                                    <el-col v-if="field.name === 'province'" :key="fieldIndex" :xs="24" :sm="12"
                                      :md="12" :lg="12" :xl="12">
                                      <el-form-item :label="$t('message.checkout.province')" prop="province"
                                        ref="provinceRefCard">
                                        <!-- 国家为【US/CA】时为select,其它为input -->
                                        <el-select v-if="selectStateCountry.includes(form.CARD.country)" filterable
                                          v-model="form.CARD.province"
                                          @visible-change="clickSelectHandler($event, 'billing', field.name)"
                                          @change="sendBillingInfoInputEvent(field.name)">
                                          <el-option v-for="(s, sindex) in STATES_OPTIONS[form.CARD.country]"
                                            :key="sindex" :label="$t(`message.state.${form.CARD.country}.${s.value}`)"
                                            :value="s.value"></el-option>
                                        </el-select>
                                        <el-input v-else maxlength="64" autocomplete="address-level1"
                                          v-model="form.CARD.province" @focus="sendBillingInfoFocusEvent(field.name)"
                                          @change="sendBillingInfoInputEvent(field.name)" />
                                      </el-form-item>
                                    </el-col>
                                    <!-- Address -->
                                    <el-col v-if="field.name === 'address'" :key="fieldIndex" :xs="24" :sm="12" :md="12"
                                      :lg="12" :xl="12">
                                      <el-form-item :label="$t('message.checkout.address')" prop="address"
                                        ref="addressRefCard">
                                        <el-input maxlength="256" autocomplete="street-address"
                                          v-model="form.CARD.address" @focus="sendBillingInfoFocusEvent(field.name)"
                                          @change="sendBillingInfoInputEvent(field.name)" />
                                      </el-form-item>
                                    </el-col>
                                    <!-- Town/City -->
                                    <el-col v-if="field.name === 'city'" :key="fieldIndex" :xs="24" :sm="12" :md="12"
                                      :lg="12" :xl="12">
                                      <el-form-item :label="$t('message.checkout.city')" prop="city" ref="cityRefCard">
                                        <el-input maxlength="64" autocomplete="address-level2" v-model="form.CARD.city"
                                          @focus="sendBillingInfoFocusEvent(field.name)"
                                          @change="sendBillingInfoInputEvent(field.name)" />
                                      </el-form-item>
                                    </el-col>
                                    <!-- 邮编 -->
                                    <el-col v-if="field.name === 'postalCode'" :key="fieldIndex" :xs="24" :sm="12"
                                      :md="12" :lg="12" :xl="12">
                                      <el-form-item :label="$t('message.checkout.postalCode')" prop="postalCode"
                                        ref="postalCodeRefCard">
                                        <el-input maxlength="32" autocomplete="postal-code"
                                          v-model="form.CARD.postalCode" @input="onPostalCodeInput"
                                          @focus="sendBillingInfoFocusEvent(field.name)"
                                          @change="sendBillingInfoInputEvent(field.name)" />
                                      </el-form-item>
                                    </el-col>
                                    <!-- 邮箱 -->
                                    <el-col v-if="field.name === 'email'" :key="fieldIndex" :xs="24" :sm="12" :md="12"
                                      :lg="12" :xl="12">
                                      <el-form-item :label="$t('message.checkout.email')" prop="email"
                                        ref="emailRefCard">
                                        <el-autocomplete :maxlength="256" autocomplete="email" v-model="form.CARD.email"
                                          :trigger-on-focus="false" :fetch-suggestions="queryEmailSearch"
                                          @focus="sendBillingInfoFocusEvent(field.name)"
                                          @change="sendBillingInfoInputEvent(field.name)" />
                                      </el-form-item>
                                    </el-col>
                                    <!-- Contact Name -->
                                    <el-col v-if="field.name === 'firstName'" :key="fieldIndex" :xs="24" :sm="12"
                                      :md="12" :lg="12" :xl="12">
                                      <el-row :gutter="device.device === 'WAP' ? 5 : 10">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                          <el-form-item
                                            :label="lang === 'ja' ? $t('message.checkout.firstName') : $t('message.checkout.contactName')"
                                            prop="firstName" ref="firstNameRefCard" class="nowrap-text">
                                            <el-input maxlength="64" autocomplete="off" v-model="form.CARD.firstName"
                                              :placeholder="$t('message.checkout.firstName')"
                                              @focus="sendBillingInfoFocusEvent(field.name)"
                                              @change="sendBillingInfoInputEvent(field.name)" />
                                          </el-form-item>
                                        </el-col>
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                          <el-form-item :label="lang === 'ja' ? $t('message.checkout.lastName') : ''"
                                            prop="lastName" ref="lastNameRefCard"
                                            :class="{ 'margin-top-23': lang !== 'ja' }">
                                            <el-input maxlength="64" autocomplete="off" v-model="form.CARD.lastName"
                                              :placeholder="$t('message.checkout.lastName')"
                                              @focus="sendBillingInfoFocusEvent('lastName')"
                                              @change="sendBillingInfoInputEvent('lastName')" />
                                          </el-form-item>
                                        </el-col>
                                      </el-row>
                                    </el-col>
                                    <!-- 手机号码 -->
                                    <el-col v-if="field.name === 'phone'" :key="fieldIndex" :xs="24" :sm="12" :md="12"
                                      :lg="12" :xl="12">
                                      <el-form-item :label="$t('message.checkout.phone')" prop="phone"
                                        ref="phoneRefCard">
                                        <el-input maxlength="32" autocomplete="off" v-model="form.CARD.phone"
                                          @focus="sendBillingInfoFocusEvent(field.name)"
                                          @change="sendBillingInfoInputEvent(field.name)" />
                                      </el-form-item>
                                    </el-col>
                                    <!-- Street -->
                                    <el-col v-if="field.name === 'street'" :key="fieldIndex" :xs="24" :sm="12" :md="12"
                                      :lg="12" :xl="12">
                                      <el-form-item :label="$t('message.checkout.street')" prop="street"
                                        ref="streetRefCard">
                                        <el-input maxlength="64" autocomplete="off" v-model="form.CARD.street"
                                          @focus="sendBillingInfoFocusEvent(field.name)"
                                          @change="sendBillingInfoInputEvent(field.name)" />
                                      </el-form-item>
                                    </el-col>
                                    <!-- 门牌号 -->
                                    <el-col v-if="field.name === 'number'" :key="fieldIndex" :xs="24" :sm="12" :md="12"
                                      :lg="12" :xl="12">
                                      <el-form-item :label="$t('message.checkout.number')" prop="number"
                                        ref="numberRefCard">
                                        <el-input maxlength="64" autocomplete="off" v-model="form.CARD.number"
                                          @focus="sendBillingInfoFocusEvent(field.name)"
                                          @change="sendBillingInfoInputEvent(field.name)" />
                                      </el-form-item>
                                    </el-col>
                                  </template>
                                  <!-- 本地支付账单地址 -->
                                  <template v-if="item.cardName !== 'CARD'">
                                    <!-- 国家 -->
                                    <el-col v-if="field.name === 'country'" :key="fieldIndex" :xs="24" :sm="12" :md="12"
                                      :lg="12" :xl="12">
                                      <el-form-item :label="$t('message.checkout.country')" prop="country"
                                        ref="countryRef">
                                        <el-select disabled filterable v-model="form.LOCAL.country"
                                          autocomplete="new-password" auto-complete="new-password">
                                          <el-option v-for="(c, cindex) in COUNTRY_OPTIONS" :key="cindex"
                                            :value="c.code" :label="$t(`message.country.${c.code}`)"></el-option>
                                        </el-select>
                                      </el-form-item>
                                    </el-col>
                                    <!-- 州 -->
                                    <el-col v-if="field.name === 'province'" :key="fieldIndex" :xs="24" :sm="12"
                                      :md="12" :lg="12" :xl="12">
                                      <el-form-item :label="$t('message.checkout.province')" prop="province"
                                        ref="provinceRef">
                                        <!-- 国家为【US/CA】时为select,其它为input -->
                                        <el-select v-if="selectStateCountry.includes(form.LOCAL.country)" filterable
                                          v-model="form.LOCAL.province"
                                          @visible-change="clickSelectHandler($event, 'billing', field.name)"
                                          @change="sendBillingInfoInputEvent(field.name)">
                                          <el-option v-for="(s, sindex) in STATES_OPTIONS[form.LOCAL.country]"
                                            :key="sindex" :value="s.value"
                                            :label="$t(`message.state.${form.LOCAL.country}.${s.value}`)"></el-option>
                                        </el-select>
                                        <el-input v-else maxlength="64" v-model="form.LOCAL.province"
                                          autocomplete="address-level1" @focus="sendBillingInfoFocusEvent(field.name)"
                                          @change="sendBillingInfoInputEvent(field.name)" />
                                      </el-form-item>
                                    </el-col>
                                    <!-- Address -->
                                    <el-col v-if="field.name === 'address'" :key="fieldIndex" :xs="24" :sm="12" :md="12"
                                      :lg="12" :xl="12">
                                      <el-form-item :label="$t('message.checkout.address')" prop="address"
                                        ref="addressRef">
                                        <el-input maxlength="256" autocomplete="street-address"
                                          v-model="form.LOCAL.address" @focus="sendBillingInfoFocusEvent(field.name)"
                                          @change="sendBillingInfoInputEvent(field.name)" />
                                      </el-form-item>
                                    </el-col>
                                    <!-- Town/City -->
                                    <el-col v-if="field.name === 'city'" :key="fieldIndex" :xs="24" :sm="12" :md="12"
                                      :lg="12" :xl="12">
                                      <el-form-item :label="$t('message.checkout.city')" prop="city" ref="cityRef">
                                        <el-input maxlength="64" autocomplete="address-level2" v-model="form.LOCAL.city"
                                          @focus="sendBillingInfoFocusEvent(field.name)"
                                          @change="sendBillingInfoInputEvent(field.name)" />
                                      </el-form-item>
                                    </el-col>
                                    <!-- 邮编 -->
                                    <el-col v-if="field.name === 'postalCode'" :key="fieldIndex" :xs="24" :sm="12"
                                      :md="12" :lg="12" :xl="12">
                                      <el-form-item :label="$t('message.checkout.postalCode')" prop="postalCode"
                                        ref="postalCodeRef">
                                        <el-input maxlength="32" autocomplete="postal-code"
                                          v-model="form.LOCAL.postalCode" @input="onPostalCodeInput"
                                          @focus="sendBillingInfoFocusEvent(field.name)"
                                          @change="sendBillingInfoInputEvent(field.name)" />
                                      </el-form-item>
                                    </el-col>
                                    <!-- 邮箱 -->
                                    <el-col v-if="field.name === 'email'" :key="fieldIndex" :xs="24" :sm="12" :md="12"
                                      :lg="12" :xl="12">
                                      <el-form-item :label="$t('message.checkout.email')" prop="email" ref="emailRef">
                                        <el-autocomplete :maxlength="256" autocomplete="email"
                                          v-model="form.LOCAL.email" :trigger-on-focus="false"
                                          :fetch-suggestions="queryEmailSearch"
                                          @focus="sendBillingInfoFocusEvent(field.name)"
                                          @change="sendBillingInfoInputEvent(field.name)" />
                                      </el-form-item>
                                    </el-col>
                                    <!-- Contact Name -->
                                    <el-col v-if="field.name === 'firstName'" :key="fieldIndex" :xs="24" :sm="12"
                                      :md="12" :lg="12" :xl="12">
                                      <el-row :gutter="device.device === 'WAP' ? 5 : 10">
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                          <el-form-item
                                            :label="lang === 'ja' ? $t('message.checkout.firstName') : $t('message.checkout.contactName')"
                                            prop="firstName" ref="firstNameRef" class="nowrap-text">
                                            <el-input maxlength="64" autocomplete="off" v-model="form.LOCAL.firstName"
                                              :placeholder="$t('message.checkout.firstName')"
                                              @focus="sendBillingInfoFocusEvent(field.name)"
                                              @change="sendBillingInfoInputEvent(field.name)" />
                                          </el-form-item>
                                        </el-col>
                                        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                          <el-form-item :label="lang === 'ja' ? $t('message.checkout.lastName') : ''"
                                            prop="lastName" ref="lastNameRef"
                                            :class="{ 'margin-top-23': lang !== 'ja' }">
                                            <el-input maxlength="64" autocomplete="off" v-model="form.LOCAL.lastName"
                                              :placeholder="$t('message.checkout.lastName')"
                                              @focus="sendBillingInfoFocusEvent('lastName')"
                                              @change="sendBillingInfoInputEvent('lastName')" />
                                          </el-form-item>
                                        </el-col>
                                      </el-row>
                                    </el-col>
                                    <!-- 手机号码 -->
                                    <el-col v-if="field.name === 'phone'" :key="fieldIndex" :xs="24" :sm="12" :md="12"
                                      :lg="12" :xl="12">
                                      <el-form-item :label="$t('message.checkout.phone')" prop="phone" ref="phoneRef">
                                        <el-input maxlength="32" autocomplete="off" v-model="form.LOCAL.phone"
                                          @focus="sendBillingInfoFocusEvent(field.name)"
                                          @change="sendBillingInfoInputEvent(field.name)" />
                                      </el-form-item>
                                    </el-col>
                                    <!-- Street -->
                                    <el-col v-if="field.name === 'street'" :key="fieldIndex" :xs="24" :sm="12" :md="12"
                                      :lg="12" :xl="12">
                                      <el-form-item :label="$t('message.checkout.street')" prop="street"
                                        ref="streetRef">
                                        <el-input maxlength="64" autocomplete="off" v-model="form.LOCAL.street"
                                          @focus="sendBillingInfoFocusEvent(field.name)"
                                          @change="sendBillingInfoInputEvent(field.name)" />
                                      </el-form-item>
                                    </el-col>
                                    <!-- 门牌号 -->
                                    <el-col v-if="field.name === 'number'" :key="fieldIndex" :xs="24" :sm="12" :md="12"
                                      :lg="12" :xl="12">
                                      <el-form-item :label="$t('message.checkout.number')" prop="number"
                                        ref="numberRef">
                                        <el-input maxlength="64" autocomplete="off" v-model="form.LOCAL.number"
                                          @focus="sendBillingInfoFocusEvent(field.name)"
                                          @change="sendBillingInfoInputEvent(field.name)" />
                                      </el-form-item>
                                    </el-col>
                                  </template>
                                </template>
                              </el-row>
                            </div>
                          </div>
                          <div class="btns"
                            v-if="(!item.children || !item.children.length) && !['DANA_VA', 'DOKU_VA'].includes(item.cardName)">
                            <div class="policy-text pc">
                              <span>{{ $t('message.checkout.policy.prefix') }}</span>
                              <el-button type="text" @click="viewPolicy">{{ $t('message.checkout.privacy')
                                }}</el-button>
                              <span>{{ $t('message.checkout.policy.suffix') }}</span>
                            </div>
                            <el-button v-if="item.cardName !== 'GooglePay' && item.cardName !== 'ApplePay'"
                              @click="submit" type="primary" class="btn-submit margin-top-12">
                              {{ $t('message.checkout.pay') }}
                              <template
                                v-if="item.cardName !== 'CARD' || (item.cardName === 'CARD' && ['', '1'].includes(form.CARD.periodValue))">{{
                                  order && order.txnCurrency }} {{ order && order.txnAmount }}</template>
                            </el-button>
                            <div v-if="item.cardName === 'ApplePay'" :class="`appleBtnContainer${index}`"
                              style="height: 44px;">
                              <apple-pay-button v-if="applePayBtnShow === 1" buttonstyle="black"
                                :type="(order && order.subProductType === 'SUBSCRIBE' && order.subscription) ? 'subscribe' : 'plain'"
                                :locale="APPLE_PAY_LANG[lang]" @click="startApplePay"></apple-pay-button>
                              <el-button v-if="applePayBtnShow === 2" disabled class="btn-submit disabled-tip">{{
                                $t('message.checkout.applePayNotSupport') }}</el-button>
                            </div>
                            <div v-if="item.cardName === 'GooglePay'" :class="`googleBtnContainer${index}`"
                              style="height: 44px;"></div>
                          </div>
                          <!-- DANA 按钮 -->
                          <div class="btns" v-if="item.cardName === 'DANA_VA'">
                            <div class="policy-text pc">
                              <span>{{ $t('message.checkout.policy.prefix') }}</span>
                              <el-button type="text" @click="viewPolicy">{{ $t('message.checkout.privacy')
                                }}</el-button>
                              <span>{{ $t('message.checkout.policy.suffix') }}</span>
                            </div>
                            <el-button :disabled="!selectedBankCode" @click="submit" type="primary"
                              class="btn-submit margin-top-12">
                              {{ $t('message.checkout.pay') }}
                              <template
                                v-if="item.cardName !== 'CARD' || (item.cardName === 'CARD' && ['', '1'].includes(form.CARD.periodValue))">{{
                                  order && order.txnCurrency }} {{ order && order.txnAmount }}</template>
                            </el-button>
                          </div>
                          <div class="btns" v-if="item.cardName === 'DOKU_VA'">
                            <div class="policy-text pc">
                              <span>{{ $t('message.checkout.policy.prefix') }}</span>
                              <el-button type="text" @click="viewPolicy">{{ $t('message.checkout.privacy')
                                }}</el-button>
                              <span>{{ $t('message.checkout.policy.suffix') }}</span>
                            </div>
                            <el-button :disabled="!selectedBankCode" @click="submit" type="primary"
                              class="btn-submit margin-top-12">
                              {{ $t('message.checkout.pay') }}
                              <template
                                v-if="item.cardName !== 'CARD' || (item.cardName === 'CARD' && ['', '1'].includes(form.CARD.periodValue))">{{
                                  order && order.txnCurrency }} {{ order && order.txnAmount }}</template>
                            </el-button>
                          </div>
                        </el-form>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </div>
              <p v-if="viewCancelBtn" class="btn-cancel-box margin-top-10"><span class="btn-cancel" @click="onCancel">{{
                $t('message.checkout.returnMerchant') }}</span></p>
            </div>
            <div class="product-content order-box margin-left-25 margin-top-20">
              <!-- 订单摘要 -->
              <div class="title">{{ $t('message.checkout.summary') }}</div>
              <div class="padding-left-20 padding-right-20 padding-bottom-24 padding-top-16 mobile-padding">
                <div class="summary-item" v-if="viewTxnId !== false">
                  <label>{{ $t('message.checkout.orderno') }}:</label>
                  <div class="content">{{ order && order.merchantTxnId }}</div>
                </div>
                <div class="summary-item" v-if="!order.hideWebsite">
                  <label>{{ $t('message.checkout.website') }}:</label>
                  <div class="content">{{ order && order.website }}</div>
                </div>
                <div class="summary-item">
                  <label>{{ $t('message.checkout.amount') }}:</label>
                  <div class="content amount">{{ order && order.txnCurrency }} {{ order && order.txnAmount }}</div>
                </div>
              </div>
              <!-- 订阅支付 -->
              <div class="subscription-wrapper"
                v-if="order && order.subProductType === 'SUBSCRIBE' && order.subscription">
                <div class="title">{{ $t('message.checkout.subscription') }}</div>
                <div class="padding-left-20 padding-right-20 padding-bottom-98 padding-top-20">
                  <div class="summary-item">
                    <label>{{ $t('message.checkout.schedule') }}:</label>
                    <div class="content">
                      <!-- {{ $t('message.checkout.frequencyData.D', {
                        time:
                          order.subscription.frequencyPoint
                      }) }} -->
                      {{ frequencyTypeLabel }}
                    </div>
                  </div>
                  <!-- <div class="summary-item">
                <label>{{$t('message.checkout.endTime')}}:</label>
                <div class="content">
                  {{$t('message.checkout.endTimeData', {
                    year: order.subscription.expireDateShow[0],
                    month: $t(`message.date.month.${order.subscription.expireDateShow[1]}`),
                    day: order.subscription.expireDateShow[2],
                  })}}
                </div>
              </div> -->
                  <div class="summary-item">
                    <label class="tip">*{{ $t('message.checkout.subscriptionTip') }}</label>
                  </div>
                </div>
              </div>
              <p v-if="viewCancelBtn" class="btn-cancel-box mobile margin-top-10"><span class="btn-cancel"
                  @click="onCancel">{{
                    $t('message.checkout.returnMerchant') }}</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Privacy :open="showPolicy" @on-close="showPolicy = false"></Privacy>
    <PaymentNotSupport :visible.sync="showPayReturnAlert" :return-url="payReturnUrl"></PaymentNotSupport>
    <ValidateCVVPopup :open="showValidateCvvPopup" @cancel="showValidateCvvPopup = false" @done="onGooglePay"
      :form="validateGooglePayCVVForm"></ValidateCVVPopup>
  </div>
</template>
<script>
import {
  getOrderInfo,
  payment,
  googlePayValidateMerchant,
} from '@/api/aggregate';
import {
  getFieldConfig,
  getInstallmentInfo,
} from '@/api/checkout';
import { addLog } from '@/api/common';
import CardNumber from '@/components/cardnumber/index.vue';
import Privacy from '@/components/privacy/index.vue';
import ValidateCVVPopup from '@/components/validate-cvv-popup/index.vue';
import { deviceFingerprintManager } from '@/plugins/deviceFingerprintManager';
import { APPLE_PAY_LANG, ApplePay } from '@/utils/tools/apple_pay';
import BANK_OPTIONS from '@/utils/tools/bank';
import COUNTRY_OPTIONS from '@/utils/tools/country';
import { DANA_BANK_OPTIONS } from '@/utils/tools/dana_banks';
import { DOKU_BANK_OPTIONS } from '@/utils/tools/doku_banks';
import EMAIL_OPTIONS from '@/utils/tools/email';
import { GooglePay } from '@/utils/tools/google_pay';
import STATES_OPTIONS from '@/utils/tools/state';
import {
  exceptionShow,
  identityNumberLabel,
  selectStateCountry,
  paymentFields,
  billingFields,
  paymentFieldsSort,
  billingFieldsSort,
  fieldConfigurationRulesAndNamesHandler,
  deleteFieldConfigurationRules,
  showMessage,
  getCardExpiredFullYear,
  // ALIPAY,
  getDevice,
  getBrowerInfo,
  showVenmoPayMethod,
  unzipOrder,
} from '@/utils/tools/utils';
import { allRules, cardFormRules, getCard, luhn } from '@/utils/validator';
import PaymentNotSupport from '@/views/aggregate/components/paymentNotSupport.vue';

export default {
  components: { CardNumber, Privacy, PaymentNotSupport, ValidateCVVPopup },
  data() {
    return {
      DANA_BANK_OPTIONS,
      DOKU_BANK_OPTIONS,
      showPolicy: false,
      marginBottom: '10px',
      device: getDevice(),
      BANK_OPTIONS,
      COUNTRY_OPTIONS,
      STATES_OPTIONS,
      // ALIPAY,
      countryList: [],
      fullscreenLoading: true,
      freshFieldConfigSuccess: false,
      order: null,
      activePaymentMethod: '',
      activeChildPaymentMethod: '',
      selectStateCountry,
      identityNumberLabel,
      payMethods: null,
      cardPayMethods: -1,
      hideCardHolderName: false,
      viewTxnId: true,
      viewCancelBtn: false,
      viewCountryBtn: true, // 是否展示国家选择项
      canShowPayReturnAlert: true,
      showPayReturnAlert: false,
      payReturnUrl: '',
      selectedBankCode: '', // dana选中的银行
      form: {
        CARD: {
          cardNum: '',
          effectiveDate: '',
          cvv: '',
          cardHolderName: '',
          walletAccountName: '',
          country: this.$route.query.c ? this.$route.query.c : '',
          province: '',
          address: '',
          city: '',
          postalCode: '',
          email: '',
          firstName: '',
          lastName: '',
          jpFirstName: '',
          jpLastName: '',
          birthDate: '',
          phone: '',
          identityNumber: '',
          street: '',
          number: '',
          intallment: 2,
          periodValue: '',
          prepaidNumber: '',
          bankName: '',
          blikcode: '',
          issueDate: '',
        },
        LOCAL: {
          cardNum: '',
          effectiveDate: '',
          cardHolderName: '',
          walletAccountName: '',
          cvv: '',
          country: this.$route.query.c ? this.$route.query.c : '',
          province: '',
          address: '',
          city: '',
          postalCode: '',
          email: '',
          firstName: '',
          lastName: '',
          jpFirstName: '',
          jpLastName: '',
          birthDate: '',
          phone: '',
          identityNumber: '',
          street: '',
          number: '',
          periodValue: '',
          bankName: '',
          iBan: '',
          prepaidNumber: '',
          blikcode: '',
          issueDate: '',
        },
      },
      older: {
        country: '',
        method: '',
        subMethod: '',
        cardNumber: '',
        cardType: '',
      },
      supportCardTypeNames: [],
      supportCardTypes: [],
      key: this.$route.query.key ? this.$route.query.key : this.$route.params.id,
      googleScriptLoaded: 'loading',
      googlePayConfig: null,
      googleBtnLoading: false,
      googlePayGatewayMerchantId: null,
      googlePayMerchantId: null,
      gatewayName: null,
      APPLE_PAY_LANG,
      applePayScriptLoaded: 'loading',
      applePayBtnLoading: false,
      applePayBtnShow: 0, // 默认0， 1显示Apple Pay, 2显示设备不支持提示
      applePayMerchantIdentifier: '',
      applePayGatewayName: null,
      refSuffix: {
        CARD: 'Card',
        LOCAL: '',
      },
      paymentType: 'CARD',
      instalmentLoading: false,
      instalmentDisabled: true,
      instalmentRefresh: true,
      instalmentInfo: null,
      showValidateCvvPopup: false,
      validateGooglePayCVVForm: {},

    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    identityNumberFormLabel() {
      if (this.form.CARD.country) {
        return identityNumberLabel[this.form.CARD.country] ? identityNumberLabel[this.form.CARD.country] : this.$t('message.checkout.identityNumber');
      }
      return this.$t('message.checkout.identityNumber');
    },
    // 这个翻译是真的不行了
    frequencyTypeLabel() {
      // 根据频率类型和频率点返回对应的订阅频率文本
      switch (this.order.subscription.frequencyType) {
      case 'D':
        if (this.lang === 'en') {
          // 英文版本：根据频率点是否为1来决定是否使用复数形式
          return ` per ${this.order.subscription.frequencyPoint} day${this.order.subscription.frequencyPoint === '1' ? '' : 's'}`;
        } else {
          // 中文版本
          return `${this.$t('message.checkout.frequency')}${this.order.subscription.frequencyPoint}${this.$t('message.checkout.day')}/${this.$t('message.checkout.times')}`;
        }
      case 'W':
        if (this.lang === 'en') {
          return ` per ${this.order.subscription.frequencyPoint} week${this.order.subscription.frequencyPoint === '1' ? '' : 's'}`;
        } else {
          return `${this.$t('message.checkout.frequency')}${this.order.subscription.frequencyPoint}${this.$t('message.checkout.week')}/${this.$t('message.checkout.times')}`;
        }
      case 'M':
        if (this.lang === 'en') {
          return ` per ${this.order.subscription.frequencyPoint} month${this.order.subscription.frequencyPoint === '1' ? '' : 's'}`;
        } else {
          return `${this.$t('message.checkout.frequency')}${this.order.subscription.frequencyPoint}${this.$t('message.checkout.month')}/${this.$t('message.checkout.times')}`;
        }
      case 'Y':
        if (this.lang === 'en') {
          return ` per ${this.order.subscription.frequencyPoint} year${this.order.subscription.frequencyPoint === '1' ? '' : 's'}`;
        } else {
          return `${this.$t('message.checkout.frequency')}${this.order.subscription.frequencyPoint}${this.$t('message.checkout.year')}/${this.$t('message.checkout.times')}`;
        }
      default:
        return '';
      }
    },
  },
  beforeCreate() {
    const key = this.$route.query.key ? this.$route.query.key : this.$route.params.id;
    // DM
    const { MD_ORI_ID, MD_MERCHANT_ID } = require('@/config/env.' + process.env.NODE_ENV);
    const script = document.createElement('script');
    script.src = `https://h.online-metrix.net/fp/tags.js?org_id=${MD_ORI_ID}&session_id=${MD_MERCHANT_ID}${key}`;
    document.body.appendChild(script);
    try {
      window.sessionStorage.setItem(`checkout_type_${key}`, '2');
      addLog({
        key,
        message: '进入收银台:' + window.location.href,
      });
    } catch (error) { }
  },
  created() {
    const { GOOGLE_PAY } = require('@/config/env.' + process.env.NODE_ENV);
    this.googlePayConfig = GOOGLE_PAY;
    this.addGooglePayScript();
    this.addApplePayScript();
    this.initMethod();
    window.addEventListener('beforeunload', this.beforeUnloadEvent);
    this.$bus.$off('lang_components').$on('lang_components', eventName => {
      this.sendGaEvent(this.order, eventName);
    });
    this.initCountryList();
  },
  beforeDestroy() {
    // gtag
    this.sendGaEvent(this.order, 'exit_checkout');
    window.removeEventListener('beforeunload', this.beforeUnloadEvent);
  },
  methods: {
    selectBank(code) {
      this.selectedBankCode = code;
    },
    initMethod() {
      try {
        const checkoutDetail = this.$route.query.checkoutDetail;
        if (checkoutDetail) {
          this.requestingTime = -1;
          const info = unzipOrder(checkoutDetail);
          if (info) {
            this.initOrder(info);
            this.fullscreenLoading = false;
            return;
          } else {
            try {
              addLog({
                key: this.key,
                message: '解密失败:' + checkoutDetail,
              });
            } catch (err) {
              //
            }
          }
        }
      } catch (err) {
        //
      }
      this.fetchOrder();
    },
    initOrder(orderInfo) {
      if (orderInfo.paymentCountry) {
        this.form.CARD.country = orderInfo.paymentCountry;
      }
      this.sendVisitRiskDeviceFingerPrint();
      this.sendGaEvent(orderInfo, 'visit_checkout');
      this.sendGaEvent(orderInfo, 'visit_checkout_time');
      if (orderInfo.status === 'F') { // F跳转，失败页面
        this.$router.push({
          path: '/result/failed',
          query: { merchantTxnId: this.order?.merchantTxnId || '', key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '2' },
        });
        return;
      }
      // shopify
      if (orderInfo.newCheckout) {
        window.location.href = orderInfo.newCheckout;
        return;
      }
      if (!orderInfo.paymentCountry) {
        this.$router.push({
          path: '/address/a',
          query: { merchantTxnId: this.order?.merchantTxnId || '', key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '2' },
        });
        return;
      }
      this.$store.dispatch('app/setBrandInfo', { ...(orderInfo.brandConfig || {}), website: orderInfo.website });
      if (orderInfo.brandConfig) {
        this.viewTxnId = orderInfo.brandConfig.viewTxnId;
        this.viewCancelBtn = orderInfo.brandConfig.viewCancelBtn;
        this.viewCountryBtn = orderInfo.brandConfig.viewCountryBtn;
      }
      this.order = orderInfo;
      this.payReturnUrl = orderInfo.returnUrl;
      if (this.order.subscription) {
        if (this.order.subscription.expireDate) {
          if (this.order.subscription.expireDate) {
            this.order.subscription.expireDateShow = this.order.subscription.expireDate.split('-');
            this.order.subscription.expireDateShow[1] = Number(this.order.subscription.expireDateShow[1]);
          } else {
            this.order.subscription.expireDateShow = ['', ''];
          }
        }
      }
      this.form.CARD.country = orderInfo.paymentCountry;
      this.form.LOCAL.country = orderInfo.paymentCountry;
      // 安卓设备不显示ApplePay
      if (orderInfo.checkoutModels && orderInfo.checkoutModels.length) {
        if (this.device.device === 'WAP' && this.device.system === 'ANDROID') {
          for (let i = 0; i < orderInfo.checkoutModels.length; i++) {
            if (orderInfo.checkoutModels[i].cardName === 'ApplePay') {
              orderInfo.checkoutModels.splice(i, 1);
              break;
            }
          }
        }
      }
      // 没有支付方式时
      if (!orderInfo.checkoutModels || !orderInfo.checkoutModels.length) {
        this.showPayReturnAlert = true;
        // showMessage(this.$t('message.result.emptyPaymentMethod'));
        this.fullscreenLoading = false;
        return;
      }
      // Venmo支付方式
      for (let i = 0; i < orderInfo.checkoutModels.length; i++) {
        const p = orderInfo.checkoutModels[i];
        if (p.cardName.toLowerCase() === 'venmo' && !showVenmoPayMethod()) {
          orderInfo.checkoutModels.splice(i, 1);
          break;
        }
      }
      // 查找卡支付
      const originPayments = orderInfo.checkoutModels;
      for (let i = 0; i < originPayments.length; i++) {
        if (originPayments[i].cardName === 'CARD') {
          this.cardPayMethods = i;
          break;
        }
      }
      this.payMethods = originPayments;
      if (this.payMethods.length > 1) {
        this.canShowPayReturnAlert = false;
      }
      // CARD支付处理
      if (this.cardPayMethods > -1) {
        this.$store.dispatch('app/setCards', this.payMethods[this.cardPayMethods].cardTypeInfo);
        // 卡信息回填
        if (orderInfo.cardInfo) {
          const cardNum = orderInfo.cardInfo.cardNumber;
          if (cardNum) {
            this.form.CARD.cardNum = cardNum.replace(/\s*/g, '').replace(/(.{4})/g, '$1 ').trim();
          }
          this.form.CARD.cvv = orderInfo.cardInfo.cvv;
          if (orderInfo.cardInfo.month && orderInfo.cardInfo.year) {
            const year = orderInfo.cardInfo.year;
            this.form.CARD.effectiveDate = `${orderInfo.cardInfo.month}/${(year).substring(year.length - 2, year.length)}`;
          }
          // 隐藏姓名输入框
          if (orderInfo.cardInfo.holderName) {
            const holderNameArr = orderInfo.cardInfo.holderName.trim().split(' ');
            if (holderNameArr.length > 1) { // holderName有空格，隐藏holderName字段
              this.hideCardHolderName = true;
            }
            this.form.CARD.cardHolderName = orderInfo.cardInfo.holderName.trim();
          }
        }
      }
      if (this.payMethods.length > 0) {
        this.activePaymentMethod = 0;
        this.older.method = 0;
        this.paymentType = this.payMethods[this.activePaymentMethod].cardName === 'CARD' ? 'CARD' : 'LOCAL';
        // 子支付方式
        if (this.payMethods[0].children && this.payMethods[0].children.length) {
          this.activeChildPaymentMethod = 0;
          this.older.subMethod = 0;
        }
      }
      for (let i = 0; i < this.payMethods.length; i++) {
        // 商户指定支付方式
        if (this.payMethods[i].isSelect === 'Y' && this.payMethods[i].cardName !== 'CARD') {
          this.activePaymentMethod = i;
          this.older.method = i;
          this.paymentType = this.payMethods[this.activePaymentMethod].cardName === 'CARD' ? 'CARD' : 'LOCAL';
          // 指定子支付方式
          if (this.payMethods[i].children && this.payMethods[i].children.length) {
            for (let j = 0; j < this.payMethods[i].children.length; j++) {
              const subMethod = this.payMethods[i].children[j];
              if (subMethod.isSelect === 'Y') {
                this.activeChildPaymentMethod = j;
                this.older.subMethod = j;
                break;
              }
            }
          }
        }
        // 排序
        if (this.payMethods[i].paymentConfigurationFields) {
          paymentFieldsSort(this.payMethods[i].paymentConfigurationFields);
        }
        if (this.payMethods[i].billConfigurationFields) {
          billingFieldsSort(this.payMethods[i].billConfigurationFields);
        }
        // 子支付方式
        if (this.payMethods[i].children && this.payMethods[i].children.length) {
          for (let j = 0; j < this.payMethods[i].children.length; j++) {
            const subMethod = this.payMethods[i].children[j];
            if (subMethod.paymentConfigurationFields) {
              paymentFieldsSort(subMethod.paymentConfigurationFields);
            }
            if (subMethod.billConfigurationFields) {
              billingFieldsSort(subMethod.billConfigurationFields);
            }
            subMethod.rules = {};
            const subMethodFields = [];
            if (subMethod.paymentConfigurationFields) {
              subMethodFields.push(...subMethod.paymentConfigurationFields);
            }
            if (subMethod.billConfigurationFields) {
              subMethodFields.push(subMethod.billConfigurationFields);
            }
            fieldConfigurationRulesAndNamesHandler(subMethodFields, subMethod.rules, this.order.paymentCountry);
            this.initFormData(subMethod.cardName, subMethodFields);
          }
        }
        const method = this.payMethods[i];
        // // 配置字段处理
        const methodFields = [];
        if (method.paymentConfigurationFields) {
          methodFields.push(...method.paymentConfigurationFields);
        }
        if (method.billConfigurationFields) {
          methodFields.push(...method.billConfigurationFields);
        }
        if (method.cardName === 'CARD') {
          method.rules = cardFormRules();
          this.cardFieldConfigurationNames = fieldConfigurationRulesAndNamesHandler(methodFields, method.rules, this.order.paymentCountry);
          this.getCardTypeInfos(method.cardTypeInfo);
        } else {
          if (method.paymentConfigurationFields) {
            method.rules = {};
            fieldConfigurationRulesAndNamesHandler(methodFields, method.rules, this.order.paymentCountry);
          }
        }
        this.initFormData(method.cardName, methodFields);
        if (method.cardName === 'GooglePay') {
          this.googlePayGatewayMerchantId = method.gatewayMerchantId;
          this.googlePayMerchantId = method.merchantId;
          this.gatewayName = method.gatewayName;
        } else if (method.cardName === 'ApplePay') {
          this.applePayMerchantIdentifier = method.merchantId;
          this.applePayGatewayName = method.gatewayName;
        }
      }
      this.fullscreenLoading = false;
      // 如果初始化页面是google pay, 初始化按钮
      if (this.payMethods[this.activePaymentMethod].cardName === 'GooglePay') {
        this.$nextTick(() => {
          this.initGooglePayButton();
        });
      } else if (this.payMethods[this.activePaymentMethod].cardName === 'ApplePay') {
        this.$nextTick(() => {
          this.initApplePayButton();
        });
      }
    },
    fetchOrder() {
      this.requestingTime = Date.now();
      getOrderInfo({
        key: this.key,
        country: this.$route.query.c,
        paymentMode: this.device.device,
        osType: this.device.system,
      }).then((res) => {
        if (res.respCode !== '20000') {
          if (exceptionShow(res.respCode, this.$router, this.$route.query.key ? this.$route.query.key : this.$route.params.id, '2')) {
            return;
          }
          this.$router.push({
            path: '/result/invalid',
            query: { merchantTxnId: this.order?.merchantTxnId || '', key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '2' },
          });
          return;
        }
        if (res.data.paymentCountry) {
          this.form.CARD.country = res.data.paymentCountry;
        }
        this.sendGaEvent(res.data, 'visit_checkout');
        this.sendVisitRiskDeviceFingerPrint();
        this.sendGaEvent(res.data, 'visit_checkout_time');
        this.sendRiskDeviceFingerPrint('visit_checkout_time');
        if (res.data.status === 'F') { // F跳转，失败页面
          this.$router.push({
            path: '/result/failed',
            query: { merchantTxnId: this.order?.merchantTxnId || '', key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '2' },
          });
          return;
        }
        // shopify
        if (res.data.newCheckout) {
          window.location.href = res.data.newCheckout;
          return;
        }
        if (!res.data.paymentCountry) {
          this.$router.push({
            path: '/address/a',
            query: { merchantTxnId: this.order?.merchantTxnId || '', key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '2' },
          });
          return;
        }
        this.$store.dispatch('app/setBrandInfo', { ...(res.data.brandConfig || {}), website: res.data.website });
        if (res.data.brandConfig) {
          this.viewTxnId = res.data.brandConfig.viewTxnId;
          this.viewCancelBtn = res.data.brandConfig.viewCancelBtn;
          this.viewCountryBtn = res.data.brandConfig.viewCountryBtn;
        }
        this.order = res.data;
        this.payReturnUrl = res.data.returnUrl;
        if (this.order.subscription) {
          if (this.order.subscription.expireDate) {
            this.order.subscription.expireDateShow = this.order.subscription.expireDate.split('-');
            this.order.subscription.expireDateShow[1] = Number(this.order.subscription.expireDateShow[1]);
          } else {
            this.order.subscription.expireDateShow = ['', ''];
          }
        }
        this.form.CARD.country = res.data.paymentCountry;
        this.form.LOCAL.country = res.data.paymentCountry;
        // 安卓设备不显示ApplePay
        if (res.data.checkoutModels && res.data.checkoutModels.length) {
          if (this.device.device === 'WAP' && this.device.system === 'ANDROID') {
            for (let i = 0; i < res.data.checkoutModels.length; i++) {
              if (res.data.checkoutModels[i].cardName === 'ApplePay') {
                res.data.checkoutModels.splice(i, 1);
                break;
              }
            }
          }
        }
        // 没有支付方式时
        if (!res.data.checkoutModels || !res.data.checkoutModels.length) {
          this.showPayReturnAlert = true;
          // showMessage(this.$t('message.result.emptyPaymentMethod'));
          this.fullscreenLoading = false;
          return;
        }
        // Venmo支付方式
        for (let i = 0; i < res.data.checkoutModels.length; i++) {
          const p = res.data.checkoutModels[i];
          if (p.cardName.toLowerCase() === 'venmo' && !showVenmoPayMethod()) {
            res.data.checkoutModels.splice(i, 1);
            break;
          }
        }
        // 查找卡支付
        const originPayments = res.data.checkoutModels;
        for (let i = 0; i < originPayments.length; i++) {
          if (originPayments[i].cardName === 'CARD') {
            this.cardPayMethods = i;
            break;
          }
        }
        this.payMethods = originPayments;
        if (this.payMethods.length > 1) {
          this.canShowPayReturnAlert = false;
        }
        // CARD支付处理
        if (this.cardPayMethods > -1) {
          this.$store.dispatch('app/setCards', this.payMethods[this.cardPayMethods].cardTypeInfo);
          // 卡信息回填
          if (res.data.cardInfo) {
            const cardNum = res.data.cardInfo.cardNumber;
            if (cardNum) {
              this.form.CARD.cardNum = cardNum.replace(/\s*/g, '').replace(/(.{4})/g, '$1 ').trim();
            }
            this.form.CARD.cvv = res.data.cardInfo.cvv;
            if (res.data.cardInfo.month && res.data.cardInfo.year) {
              const year = res.data.cardInfo.year;
              this.form.CARD.effectiveDate = `${res.data.cardInfo.month}/${(year).substring(year.length - 2, year.length)}`;
            }
            // 隐藏姓名输入框
            if (res.data.cardInfo.holderName) {
              const holderNameArr = res.data.cardInfo.holderName.trim().split(' ');
              if (holderNameArr.length > 1) { // holderName有空格，隐藏holderName字段
                this.hideCardHolderName = true;
              }
              this.form.CARD.cardHolderName = res.data.cardInfo.holderName.trim();
            }
          }
        }
        if (this.payMethods.length > 0) {
          this.activePaymentMethod = 0;
          this.older.method = 0;
          this.paymentType = this.payMethods[this.activePaymentMethod].cardName === 'CARD' ? 'CARD' : 'LOCAL';
          // 子支付方式
          if (this.payMethods[0].children && this.payMethods[0].children.length) {
            this.activeChildPaymentMethod = 0;
            this.older.subMethod = 0;
          }
        }
        for (let i = 0; i < this.payMethods.length; i++) {
          // 商户指定支付方式
          if (this.payMethods[i].isSelect === 'Y' && this.payMethods[i].cardName !== 'CARD') {
            this.activePaymentMethod = i;
            this.older.method = i;
            this.paymentType = this.payMethods[this.activePaymentMethod].cardName === 'CARD' ? 'CARD' : 'LOCAL';
            // 指定子支付方式
            if (this.payMethods[i].children && this.payMethods[i].children.length) {
              for (let j = 0; j < this.payMethods[i].children.length; j++) {
                const subMethod = this.payMethods[i].children[j];
                if (subMethod.isSelect === 'Y') {
                  this.activeChildPaymentMethod = j;
                  this.older.subMethod = j;
                  break;
                }
              }
            }
          }
          // 排序
          if (this.payMethods[i].paymentConfigurationFields) {
            paymentFieldsSort(this.payMethods[i].paymentConfigurationFields);
          }
          if (this.payMethods[i].billConfigurationFields) {
            billingFieldsSort(this.payMethods[i].billConfigurationFields);
          }
          // 子支付方式
          if (this.payMethods[i].children && this.payMethods[i].children.length) {
            for (let j = 0; j < this.payMethods[i].children.length; j++) {
              const subMethod = this.payMethods[i].children[j];
              if (subMethod.paymentConfigurationFields) {
                paymentFieldsSort(subMethod.paymentConfigurationFields);
              }
              if (subMethod.billConfigurationFields) {
                billingFieldsSort(subMethod.billConfigurationFields);
              }
              subMethod.rules = {};
              const subMethodFields = [];
              if (subMethod.paymentConfigurationFields) {
                subMethodFields.push(...subMethod.paymentConfigurationFields);
              }
              if (subMethod.billConfigurationFields) {
                subMethodFields.push(subMethod.billConfigurationFields);
              }
              fieldConfigurationRulesAndNamesHandler(subMethodFields, subMethod.rules, this.order.paymentCountry);
              this.initFormData(subMethod.cardName, subMethodFields);
            }
          }
          const method = this.payMethods[i];
          // // 配置字段处理
          const methodFields = [];
          if (method.paymentConfigurationFields) {
            methodFields.push(...method.paymentConfigurationFields);
          }
          if (method.billConfigurationFields) {
            methodFields.push(...method.billConfigurationFields);
          }
          if (method.cardName === 'CARD') {
            method.rules = cardFormRules();
            this.cardFieldConfigurationNames = fieldConfigurationRulesAndNamesHandler(methodFields, method.rules, this.order.paymentCountry);
            this.getCardTypeInfos(method.cardTypeInfo);
          } else {
            if (method.paymentConfigurationFields) {
              method.rules = {};
              fieldConfigurationRulesAndNamesHandler(methodFields, method.rules, this.order.paymentCountry);
            }
          }
          this.initFormData(method.cardName, methodFields);
          if (method.cardName === 'GooglePay') {
            this.googlePayMerchantId = method.merchantId;
            this.gatewayName = method.gatewayName;
          } else if (method.cardName === 'ApplePay') {
            this.applePayMerchantIdentifier = method.merchantId;
            this.applePayGatewayName = method.gatewayName;
          }
        }
        this.fullscreenLoading = false;
        // 如果初始化页面是google pay, 初始化按钮
        if (this.payMethods[this.activePaymentMethod].cardName === 'GooglePay') {
          this.$nextTick(() => {
            this.initGooglePayButton();
          });
        } else if (this.payMethods[this.activePaymentMethod].cardName === 'ApplePay') {
          this.$nextTick(() => {
            this.initApplePayButton();
          });
        }
      }).catch((error) => {
        this.fullscreenLoading = false;
        this.$router.push({
          path: '/result/invalid',
          query: { merchantTxnId: this.order?.merchantTxnId || '', key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '2' },
        });
      });
    },
    methodChange(value) {
      this.paymentType = this.payMethods[value].cardName === 'CARD' ? 'CARD' : 'LOCAL';
      // 当卡支付和本地支付国家一致时，账单地址数据不隔离，否则账单地址数据隔离
      const newMethod = this.payMethods[value].cardName;
      const oldMethod = this.payMethods[this.older.method].cardName;
      if ((newMethod === 'CARD' || oldMethod === 'CARD') && (this.form.CARD.country === this.form.LOCAL.country)) {
        if (newMethod === 'CARD') {
          for (const localItem in this.form.LOCAL) {
            if (this.form.LOCAL[localItem]) {
              this.form.CARD[localItem] = this.form.LOCAL[localItem];
            }
          }
        } else {
          for (const cardItem in this.form.CARD) {
            if (this.form.CARD[cardItem]) {
              this.form.LOCAL[cardItem] = this.form.CARD[cardItem];
            }
          }
        }
      }
      this.older.method = value;
      if (newMethod === 'GooglePay') {
        this.initGooglePayButton();
      } else if (newMethod === 'ApplePay') {
        this.initApplePayButton();
      }
      this.sendGaEvent(this.order, 'select_payment_method');
      this.sendRiskDeviceFingerPrint('select_payment_method');
    },
    subMethodChange(value) {
      this.$refs.formRef[this.activePaymentMethod].clearValidate();
    },
    cardNumberBlur() {
      const cardNum = this.form[this.paymentType].cardNum.replace(/\s*/g, '');
      const card = getCard(this.form[this.paymentType].cardNum, false);

      // 若输出卡种不对，则分期不可选，否则分期可选
      if (cardNum.length > 10 && luhn(cardNum)) {
        this.instalmentDisabled = false;
        if (this.older.cardNum !== cardNum) {
          this.form.CARD.periodValue = '';
          this.instalmentRefresh = true;
        }
      } else {
        this.instalmentDisabled = true;
        this.form.CARD.periodValue = '';
      }
      /**
       * 更新卡种、账单、分期信息
       * 1. 没找到前端正则匹配卡种，不同卡号，且卡号长度>10
       * 2. 前端正则可匹配到卡种，且与老卡种不同, 且卡号长度>10
       */
      const diffCardNumber = !card && this.older.cardNum !== cardNum;
      const diffCardType = card && card.cardType !== this.older.cardType;
      if ((diffCardNumber || diffCardType || !this.freshFieldConfigSuccess) && cardNum.length > 10 && luhn(cardNum)) {
        this.fieldConfigurationUpdate(cardNum);
      }
      this.older.cardNum = cardNum;
      if (card) {
        this.older.cardType = card.cardType;
      }
      if (cardNum > 0) {
        this.formInlineItemBlurHandler();
      }
    },
    onIdentityNumberInput() {
      const country = this.form[this.paymentType].country;
      const value = this.form[this.paymentType].identityNumber;
      if (country === 'BR' && value) {
        let newValue = value.replace(/[^\d]/g, '');
        if (newValue.length === 11) {
          newValue = `${newValue.slice(0, 3)}.${newValue.slice(3, 6)}.${newValue.slice(6, 9)}-${newValue.slice(9, 11)}`;
        } else if (newValue.length === 14) {
          newValue = `${newValue.slice(0, 2)}.${newValue.slice(2, 5)}.${newValue.slice(5, 8)}/${newValue.slice(8, 12)}-${newValue.slice(12, 14)}`;
        }
        this.form[this.paymentType].identityNumber = newValue;
      }
    },
    onPostalCodeInput() {
      const country = this.form[this.paymentType].country;
      const value = this.form[this.paymentType].postalCode;
      if (country === 'US' && value) {
        if (value.length === 9 && value.indexOf('-') === -1) {
          const newValue = `${value.slice(0, 5)}-${value.slice(5, value.length)}`;
          this.form[this.paymentType].postalCode = newValue;
        }
      }
    },
    keyupName(evt, key) {
      this.form.CARD[key] = evt.target.value.replace(/[\u4e00-\u9fa5]/ig, '');
    },
    effectiveDateChangeHandler(evt) {
      if (evt.inputType === 'deleteContentBackward') {
        return;
      }
      const cardName = this.payMethods[this.activePaymentMethod].cardName;
      const formType = cardName === 'CARD' ? 'CARD' : 'LOCAL';
      this.form[formType].effectiveDate = this.form[formType].effectiveDate.replace(/[^\d/]/g, '');
      if (this.form[formType].effectiveDate.length > 0) {
        const dateArray = this.form[formType].effectiveDate.split('');
        if (dateArray[0] === '0' && dateArray[1] === '0') {
          dateArray[1] = 1;
          this.form[formType].effectiveDate = dateArray.join('');
        }
        if (dateArray.length >= 2 && dateArray.filter(i => { return i === '/'; }).length < 1) {
          dateArray[2] = '/';
          this.form[formType].effectiveDate = dateArray.join('');
        }
      }
    },
    issueDateChangeHandler(evt) {
      if (evt.inputType === 'deleteContentBackward') {
        return;
      }
      const cardName = this.payMethods[this.activePaymentMethod].cardName;
      const formType = cardName === 'CARD' ? 'CARD' : 'LOCAL';
      this.form[formType].issueDate = this.form[formType].issueDate.replace(/[^\d/]/g, '');
      if (this.form[formType].issueDate.length > 0) {
        const dateArray = this.form[formType].issueDate.split('');
        if (dateArray[0] === '0' && dateArray[1] === '0') {
          dateArray[1] = 1;
          this.form[formType].issueDate = dateArray.join('');
        }
        if (dateArray.length >= 2 && dateArray.filter(i => { return i === '/'; }).length < 1) {
          dateArray[2] = '/';
          this.form[formType].issueDate = dateArray.join('');
        }
      }
    },
    birthDateChangeHandler(evt) {
      if (evt.inputType === 'deleteContentBackward') {
        return;
      }
      const cardName = this.payMethods[this.activePaymentMethod].cardName;
      const formType = cardName === 'CARD' ? 'CARD' : 'LOCAL';
      this.form[formType].birthDate = this.form[formType].birthDate.replace(/[^\d/]/g, '');
      if (this.form[formType].birthDate.length > 0) {
        const dateArray = this.form[formType].birthDate.split('');
        if (dateArray.length >= 4 && dateArray.filter(i => { return i === '/'; }).length < 1) {
          dateArray[4] = '/';
          this.form[formType].birthDate = dateArray.join('');
        }
        if (dateArray.length >= 7 && dateArray.filter(i => { return i === '/'; }).length < 2) {
          dateArray[7] = '/';
          this.form[formType].birthDate = dateArray.join('');
        }
      }
    },
    cvvChangeHandler() {
      if (this.paymentType === 'CARD') {
        this.form.CARD.cvv = this.form.CARD.cvv.replace(/[^\d]/g, '');
      } else {
        this.form.LOCAL.cvv = this.form.LOCAL.cvv.replace(/[^\d]/g, '');
      }
    },
    countryChange(value) { // 只有卡支付会触发此动作
      // 卡支付时国家变化，特定国家identityNumber字段名变化
      if (this.cardFieldConfigurationNames.includes('identityNumber')) {
        if ((!!this.identityNumberLabel[value]) !== (!!this.identityNumberLabel[this.older.country])) { // identityNumber Label有变动
          const rules = this.payMethods[this.activePaymentMethod].rules;
          if (rules.identityNumber && rules.identityNumber.length) {
            if (this.identityNumberLabel[value]) {
              rules.identityNumber[0] = allRules.required(this.identityNumberLabel[value], false, false);
            } else {
              rules.identityNumber[0] = allRules.required('message.checkout.identityNumber');
            }
          }
          const fieldRef = this.$refs.identityNumberRefCard[0];
          if (fieldRef.$el.className.indexOf('is-error') > -1) {
            this.$refs.formRef[this.activePaymentMethod].validateField('identityNumber');
          }
        }
      }
      // CARD支付国家切换时，state如果不符合，需要置空
      if (selectStateCountry.includes(value)) {
        let hasProvince = false;
        for (let i = 0; i < STATES_OPTIONS[value].length; i++) {
          if (this.form.CARD.province === STATES_OPTIONS[value][i].value) {
            hasProvince = true;
            break;
          }
        }
        if (!hasProvince) {
          this.form.CARD.province = '';
        }
      }
      // 国家变化，根据帐单国家或卡种+帐单国家动态获取字段配置中的帐单地址，不论是否分期支付
      this.fieldConfigurationUpdate(this.older.cardNumber);
      this.older.country = value;
    },
    countryChangeHandler(value) { // 切换国家
      this.fullscreenLoading = true;
      this.order = null;
      // 置空分期信息
      this.form.CARD.periodValue = '';
      this.instalmentInfo = null;
      this.instalmentRefresh = true;
      getOrderInfo({
        key: this.$route.query.key ? this.$route.query.key : this.$route.params.id,
        country: value,
        paymentMode: this.device.device,
        osType: this.device.system,
      }).then((res) => {
        if (res.respCode !== '20000') {
          if (exceptionShow(res.respCode, this.$router, this.$route.query.key ? this.$route.query.key : this.$route.params.id, '2')) {
            return;
          }
          this.$router.push({
            path: '/result/invalid',
            query: { merchantTxnId: this.order?.merchantTxnId || '', key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '2' },
          });
          return;
        }
        if (res.data.status === 'F') { // F跳转，失败页面
          this.$router.push({
            path: '/result/failed',
            query: { merchantTxnId: this.order?.merchantTxnId || '', key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '2' },
          });
          return;
        }
        if (!res.data.paymentCountry) {
          this.$router.push({
            path: '/address/a',
            query: { merchantTxnId: this.order?.merchantTxnId || '', key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '2' },
          });
          return;
        }
        this.$store.dispatch('app/setBrandInfo', { ...(res.data.brandConfig || {}), website: res.data.website });
        if (res.data.brandConfig) {
          this.viewTxnId = res.data.brandConfig.viewTxnId;
          this.viewCancelBtn = res.data.brandConfig.viewCancelBtn;
          this.viewCountryBtn = res.data.brandConfig.viewCountryBtn;
        }
        this.order = res.data;
        if (this.order.subscription) {
          if (this.order.subscription.expireDate) {
            this.order.subscription.expireDateShow = this.order.subscription.expireDate.split('-');
            this.order.subscription.expireDateShow[1] = Number(this.order.subscription.expireDateShow[1]);
          } else {
            this.order.subscription.expireDateShow = ['', ''];
          }
        }
        this.form.CARD.country = res.data.paymentCountry;
        this.form.LOCAL.country = res.data.paymentCountry;
        // 安卓设备不显示ApplePay
        if (res.data.checkoutModels && res.data.checkoutModels.length) {
          if (this.device.device === 'WAP' && this.device.system === 'ANDROID') {
            for (let i = 0; i < res.data.checkoutModels.length; i++) {
              if (res.data.checkoutModels[i].cardName === 'ApplePay') {
                res.data.checkoutModels.splice(i, 1);
                break;
              }
            }
          }
        }
        // 没有支付方式时
        if (!res.data.checkoutModels || !res.data.checkoutModels.length) {
          showMessage(this.$t('message.result.emptyPaymentMethod'));
          this.payMethods = null;
          this.fullscreenLoading = false;
          return;
        }
        // Venmo支付方式
        for (let i = 0; i < res.data.checkoutModels.length; i++) {
          const p = res.data.checkoutModels[i];
          if (p.cardName.toLowerCase() === 'venmo' && !showVenmoPayMethod()) {
            res.data.checkoutModels.splice(i, 1);
            break;
          }
        }
        // 查找卡支付
        const originPayments = res.data.checkoutModels;
        this.cardPayMethods = -1;
        for (let i = 0; i < originPayments.length; i++) {
          if (originPayments[i].cardName === 'CARD') {
            this.cardPayMethods = i;
            break;
          }
        }
        this.payMethods = originPayments;
        debugger;
        // CARD支付处理
        if (this.cardPayMethods > -1) {
          this.$store.dispatch('app/setCards', this.payMethods[this.cardPayMethods].cardTypeInfo);
          this.getCardTypeInfos(this.payMethods[this.cardPayMethods].cardTypeInfo);
        }
        // 默认选中第一个
        if (this.payMethods.length > 0) {
          this.activePaymentMethod = 0;
          this.older.method = 0;
          this.activeChildPaymentMethod = '';
          this.older.subMethod = '';
          if (this.payMethods[0].children && this.payMethods[0].children.length) {
            this.activeChildPaymentMethod = 0;
            this.older.subMethod = 0;
          }
          this.paymentType = this.payMethods[this.activePaymentMethod].cardName === 'CARD' ? 'CARD' : 'LOCAL';
        }
        for (let i = 0; i < this.payMethods.length; i++) {
          // 商户指定支付方式
          if (this.payMethods[i].isSelect === 'Y' && this.payMethods[i].cardName !== 'CARD') {
            this.activePaymentMethod = i;
            this.older.method = i;
            this.paymentType = this.payMethods[this.activePaymentMethod].cardName === 'CARD' ? 'CARD' : 'LOCAL';
            if (this.payMethods[i].children && this.payMethods[i].children.length) {
              for (let j = 0; j < this.payMethods[i].children.length; j++) {
                const subMethod = this.payMethods[i].children[j];
                if (subMethod.isSelect === 'Y') {
                  this.activeChildPaymentMethod = j;
                  this.older.subMethod = j;
                  break;
                }
              }
            }
          }
          // 排序
          if (this.payMethods[i].paymentConfigurationFields) {
            paymentFieldsSort(this.payMethods[i].paymentConfigurationFields);
          }
          if (this.payMethods[i].billConfigurationFields) {
            billingFieldsSort(this.payMethods[i].billConfigurationFields);
          }
          // 子支付方式
          if (this.payMethods[i].children && this.payMethods[i].children.length) {
            for (let j = 0; j < this.payMethods[i].children.length; j++) {
              const subMethod = this.payMethods[i].children[j];
              paymentFieldsSort(subMethod.paymentConfigurationFields);
              billingFieldsSort(subMethod.billingFieldsSort);
              subMethod.rules = {};
              const subMethodFields = [];
              if (subMethod.paymentConfigurationFields) {
                subMethodFields.push(...subMethod.paymentConfigurationFields);
              }
              if (subMethod.billConfigurationFields) {
                subMethodFields.push(...subMethod.billConfigurationFields);
              }
              fieldConfigurationRulesAndNamesHandler(subMethodFields, subMethod.rules, this.order.paymentCountry);
              this.initFormData(subMethod.cardName, subMethodFields);
            }
          }
          const method = this.payMethods[i];
          const methodFields = [];
          if (method.paymentConfigurationFields) {
            methodFields.push(...method.paymentConfigurationFields);
          }
          if (method.billConfigurationFields) {
            methodFields.push(...method.billConfigurationFields);
          }
          // 配置字段处理
          if (method.cardName === 'CARD') {
            method.rules = cardFormRules();
            this.cardFieldConfigurationNames = fieldConfigurationRulesAndNamesHandler(methodFields, method.rules, this.order.paymentCountry);
          } else {
            method.rules = {};
            fieldConfigurationRulesAndNamesHandler(methodFields, method.rules, this.order.paymentCountry);
          }
          this.initFormData(method.cardName, methodFields);
          if (method.cardName === 'GooglePay') {
            this.googlePayMerchantId = method.merchantId;
            this.gatewayName = method.gatewayName;
          } else if (method.cardName === 'ApplePay') {
            this.applePayMerchantIdentifier = method.merchantId;
            this.applePayGatewayName = method.gatewayName;
          }
        }
        // 切换国家，初始化
        if (this.payMethods[this.activePaymentMethod].cardName === 'GooglePay') {
          this.$nextTick(() => {
            this.initGooglePayButton();
          });
        } else if (this.payMethods[this.activePaymentMethod].cardName === 'ApplePay') {
          this.$nextTick(() => {
            this.initApplePayButton();
          });
        }
        this.fullscreenLoading = false;
      }).catch(() => {
        this.fullscreenLoading = false;
        this.$router.push({
          path: '/result/invalid',
          query: { merchantTxnId: this.order?.merchantTxnId || '', key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '2' },
        });
      });
      this.sendGaEvent(this.order, 'select_country');
    },
    getInstalmentList() {
      if (!this.instalmentRefresh) {
        return;
      }
      this.instalmentLoading = true;
      const params = {
        appId: this.order.appId,
        merchantNo: this.order.merchantNo,
        amount: this.order.txnAmount,
        currency: this.order.txnCurrency,
        cardInfo: {
          cardNumber: this.form.CARD.cardNum.replace(/\s*/g, ''),
        },
        country: this.form.CARD.country,
      };
      getInstallmentInfo(params).then((res) => {
        if (res.respCode === '20000') {
          this.instalmentRefresh = false;
          this.instalmentInfo = res.data;
          if (this.instalmentInfo && this.instalmentInfo.stages && this.instalmentInfo.stages.length === 1 && this.instalmentInfo.stages[0].stageNumber === '1') {
            this.$nextTick(() => {
              setTimeout(() => {
                this.form.CARD.periodValue = '1';
              }, 0);
            });
          }
        }
        this.instalmentLoading = false;
      }).catch(() => {
        this.instalmentLoading = false;
        showMessage(this.$t('message.error.timeout'));
      });
    },
    intallmentChange(item) {
      // 当选择分期，分期没选项时提示错误消息
      const value = this.form.CARD.intallment;
      if (value === 1 && (!item.stagesInformation || !item.stagesInformation.length)) {
        this.form.CARD.intallment = 2;
        this.form.CARD.periodValue = '';
        showMessage(this.$t('message.validator.intallmentCardOrCountryNotSupport'));
      }
    },
    initFormData(type, fieldConfiguration) {
      const formType = type === 'CARD' ? 'CARD' : 'LOCAL';
      fieldConfiguration && fieldConfiguration.forEach((field) => {
        if (field.value && !['cardNum', 'cvv', 'effectiveDate', 'cardHolderName'].includes(field.name)) {
          this.form[formType][field.name] = field.value;
          if (field.name === 'province' && selectStateCountry.includes(this.order.paymentCountry)) {
            let rightProvince = false;
            for (let i = 0; i < STATES_OPTIONS[this.order.paymentCountry].length; i++) {
              if (field.value && (field.value.toUpperCase().trim() === STATES_OPTIONS[this.order.paymentCountry][i].value)) {
                this.form[formType][field.name] = field.value.toUpperCase().trim();
                rightProvince = true;
                break;
              }
            }
            if (!rightProvince) {
              this.form[formType][field.name] = '';
            }
          }

          if (field.name === 'identityNumber') { // 格式化 identityNumber
            this.onIdentityNumberInput();
          }

          if (field.name === 'postalCode') {
            this.onPostalCodeInput();
          }
        }
      });
    },
    fieldConfigurationUpdate(cardNumber) { // 卡支付更新配置信息
      this.fullscreenLoading = true;
      // 置空分期信息
      this.form.CARD.periodValue = '';
      this.instalmentInfo = null;
      this.instalmentRefresh = true;
      getFieldConfig({
        key: this.$route.query.key ? this.$route.query.key : this.$route.params.id,
        cardNumber,
        country: this.form.CARD.country,
      }).then((res) => {
        if (res.respCode !== '20000') {
          if (exceptionShow(res.respCode, this.$router, this.$route.query.key ? this.$route.query.key : this.$route.params.id, '2')) {
            return;
          }
          this.freshFieldConfigSuccess = false;
          this.fullscreenLoading = false;
          if (res.respCode === '00020|50036') {
            showMessage(this.$t('message.error.cardNumber'));
          } else {
            showMessage(this.$t('message.error.timeout'));
          }
          return;
        }
        this.freshFieldConfigSuccess = true;
        // 更新fieldConfiguration信息
        deleteFieldConfigurationRules(this.payMethods[this.cardPayMethods].rules);
        paymentFieldsSort(res.data.paymentConfigurationFields);
        billingFieldsSort(res.data.billConfigurationFields);
        const fields = [];
        if (res.data.paymentConfigurationFields) {
          fields.push(...res.data.paymentConfigurationFields);
        }
        if (res.data.billConfigurationFields) {
          fields.push(...res.data.billConfigurationFields);
        }
        this.cardFieldConfigurationNames = fieldConfigurationRulesAndNamesHandler(fields, this.payMethods[this.cardPayMethods].rules, this.order.paymentCountry);
        this.payMethods[this.cardPayMethods].rules = { ...this.payMethods[this.cardPayMethods].rules };
        // 更新页面的验证提示消息
        this.$nextTick(() => {
          const timer = setTimeout(() => {
            this.$refs.formRef[this.activePaymentMethod].validate();
            clearTimeout(timer);
          });
        });
        this.payMethods[this.cardPayMethods].paymentConfigurationFields = res.data.paymentConfigurationFields;
        this.payMethods[this.cardPayMethods].billConfigurationFields = res.data.billConfigurationFields;
        this.order.canInstallment = res.data.canInstallment;
        this.fullscreenLoading = false;
      }).catch(() => {
        this.fullscreenLoading = false;
        this.freshFieldConfigSuccess = false;
        showMessage(this.$t('message.error.timeout'));
      });
    },
    submit() {
      this.$refs.formRef[this.activePaymentMethod].validate((valid) => {
        this.sendGaEvent(this.order, 'click_payment_button');
        this.sendRiskDeviceFingerPrint('click_payment_button');
        this.sendGaEvent(this.order, 'click_result', '', { result: valid ? 'S' : 'F' });
        this.sendRiskDeviceFingerPrint('click_result');
        if (valid) {
          this.fullscreenLoading = true;
          const submitData = this.getPaymentData();
          this.requestPayment(submitData);
        } else {
          this.$nextTick(() => {
            const errorDom = document.querySelector('.is-error');
            if (errorDom) {
              errorDom.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            }
          });
        }
      });
      this.formInlineItemBlurHandler();
    },
    requestPayment(submitData, applePaySession = null) {
      payment(submitData).then((res) => {
        try {
          this.sendGaEvent(this.order, 'payment_result', '', { result: res.data && res.data.status ? res.data.status : 'F', result_desc: res.respMsg });
          this.sendRiskDeviceFingerPrint('payment_result');
        } catch (err) { }
        if (res.respCode === '20000') {
          if (res.data.status === 'F') {
            if (applePaySession) {
              applePaySession.completePayment(applePaySession.STATUS_FAILURE);
            }
            this.$router.push({
              path: '/result/failed',
              query: { merchantTxnId: this.order?.merchantTxnId || '', key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '2' },
            });
          } if (res.data.status === 'S') {
            if (applePaySession) {
              applePaySession.completePayment(applePaySession.STATUS_SUCCESS);
            }
            this.$router.push({
              path: '/result/success',
              query: { merchantTxnId: this.order?.merchantTxnId || '', key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '2' },
            });
          } else if (res.data.status === 'P') {
            if (applePaySession) {
              applePaySession.completePayment(applePaySession.STATUS_SUCCESS);
            }
            this.$router.push({
              path: '/result/pending',
              query: { merchantTxnId: this.order?.merchantTxnId || '', key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '2' },
            });
          } else if (res.data.status === 'R') {
            if (applePaySession) {
              applePaySession.completePayment(applePaySession.STATUS_SUCCESS);
            }
            if (res.data.redirectEnum === 'QrCode') {
              // 取displayType=IMAGE的作为QRCODE
              let qrcode = '';
              if (res.data.codeForm.codeDetails) {
                for (let i = 0; i < res.data.codeForm.codeDetails.length; i++) {
                  if (res.data.codeForm.codeDetails[i].displayType === 'IMAGE') {
                    qrcode = res.data.codeForm.codeDetails[i].codeValue;
                    break;
                  }
                }
              }
              this.$router.push({
                path: '/payment/qrcode',
                query: {
                  merchantTxnId: this.order?.merchantTxnId || '',
                  key: this.$route.query.key ? this.$route.query.key : this.$route.params.id,
                  code: qrcode,
                  type: '2',
                },
              });
            } else if (res.data.redirectEnum === 'ShowContext') {
              const { paymentMethod } = res.data;
              const _paymentMethod = paymentMethod.toUpperCase();
              const payType = _paymentMethod.split('_')[0];
              switch (payType) {
              // QR_PH:这个值是后端配置的 后端配置的时候一定要存在QR且已"QR_"开头
              case 'QR':
                this.$router.push({
                  // 这是个公共页面QR页面
                  path: '/payment/local/qrcode',
                  // eslint-disable-next-line indent
                    query: {
                    txnId: res.data.txnId,
                    type: '2',
                  },
                });
                break;
              case 'DANA':
                this.$router.push({
                  // 这是个公共页面QR页面
                  path: '/payment/local/dana',
                  // eslint-disable-next-line indent
                    query: {
                    txnId: res.data.txnId,
                    type: '2',
                  },
                });
                break;
              case 'DOKU':
                this.$router.push({
                  path: '/payment/local/doku',
                  // eslint-disable-next-line indent
                    query: {
                    txnId: res.data.txnId,
                    type: '2',
                  },
                });
                break;
              case 'QRIS':
                this.$router.push({
                  path: '/payment/local/qris',
                  // eslint-disable-next-line indent
                    query: {
                    txnId: res.data.txnId,
                    type: '2',
                  },
                });
                break;
              default:
                // 历史遗留 默认的PIX支付页面
                this.$router.push({
                  path: '/payment/local',
                  query: {
                    txnId: res.data.txnId,
                    type: '2',
                  },
                });
                break;
              }
            } else {
              const { activePaymentMethod, payMethods } = this;
              const currentMethod = payMethods.find((item, index) => index === activePaymentMethod);
              if (currentMethod && currentMethod.cardName === 'NINE_PAY_EWALLET') {
                const link = document.createElement('a');
                link.href = res.data.redirectUrl;
                link.rel = 'noreferrer';
                document.body.appendChild(link);
                link.click(); // 模拟点击跳转
                document.body.removeChild(link);
                return;
              }
              window.location.href = res.data.redirectUrl;
            }
          }
        } else {
          if (applePaySession) {
            applePaySession.completePayment(applePaySession.STATUS_FAILURE);
          }
          if (!exceptionShow(res.respCode, this.$router, this.$route.query.key ? this.$route.query.key : this.$route.params.id, '2')) {
            this.$router.push({
              path: '/result/failed',
              query: { merchantTxnId: this.order?.merchantTxnId || '', key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '2', error: res.respMsg ? encodeURIComponent(res.respMsg) : '' },
            });
          } else {
            this.fullscreenLoading = false;
          }
        }
      }).catch(() => {
        if (applePaySession) {
          applePaySession.completePayment(applePaySession.STATUS_FAILURE);
        }
        this.$router.push({
          path: '/result/failed',
          query: { merchantTxnId: this.order?.merchantTxnId || '', key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '2' },
        });
      });
    },
    updateValidateTip() { // 卡支付更新配置字段时触发
      [...paymentFields, ...billingFields].forEach((field) => {
        const element = this.$refs[`${field}RefCard`] && this.$refs[`${field}RefCard`][0];
        if (element) {
          if (element.$el.className.indexOf('is-error') > -1) {
            this.$refs.formRef[this.activePaymentMethod].clearValidate(field);
          }
        }
      });
    },
    getPaymentData(payToken = '') {
      const currentMethod = this.payMethods[this.activePaymentMethod];
      const fieldConfiguration = [];
      if (currentMethod.paymentConfigurationFields) {
        fieldConfiguration.push(...currentMethod.paymentConfigurationFields);
      }
      if (currentMethod.billConfigurationFields) {
        fieldConfiguration.push(...currentMethod.billConfigurationFields);
      }
      let methodType = currentMethod.cardName;
      if (currentMethod.children && currentMethod.children.length) {
        const subMethod = currentMethod.children[this.activeChildPaymentMethod];
        methodType = subMethod.cardName;
        if (subMethod.paymentConfigurationFields) {
          fieldConfiguration.push(...subMethod.paymentConfigurationFields);
        }
        if (subMethod.billConfigurationFields) {
          fieldConfiguration.push(...subMethod.billConfigurationFields);
        }
      }
      const browerInfo = getBrowerInfo();
      const submitData = {
        key: this.$route.query.key ? this.$route.query.key : this.$route.params.id,
        cardName: methodType,
        paymentMode: this.device.device,
        osType: this.device.system,
        sessionId: this.key,
        referer: window.document.referrer,
        ...browerInfo,
      };
      if (methodType === 'CARD') {
        submitData.cardNumber = this.form.CARD.cardNum.replace(/\s*/g, '');
        submitData.month = this.form.CARD.effectiveDate.split('/')[0];
        submitData.year = getCardExpiredFullYear(this.form.CARD.effectiveDate.split('/')[1]);
        submitData.cvv = this.form.CARD.cvv;
        submitData.holderName = this.form.CARD.cardHolderName.trim();
        if (this.form.CARD.periodValue) {
          submitData.periodValue = this.form.CARD.periodValue;
          if (this.instalmentInfo.institution === 'DLOCAL') {
            for (let i = 0; i < this.instalmentInfo.stages.length; i++) {
              const stage = this.instalmentInfo.stages[i];
              if (stage.stageNumber === this.form.CARD.periodValue) {
                submitData.installmentPlanId = stage.installmentPlanId;
                break;
              }
            }
          }
        }
        if (fieldConfiguration && fieldConfiguration.length) {
          fieldConfiguration.forEach((item) => {
            if (!['cardNum', 'effectiveDate', 'cardHolderName'].includes(item.name)) {
              submitData[item.name] = this.form.CARD[item.name];
            }
          });
        }
      } else {
        if (fieldConfiguration && fieldConfiguration.length) {
          fieldConfiguration.forEach((item) => {
            if (item.name === 'cardNum') {
              submitData.cardNumber = this.form.LOCAL.cardNum.replace(/\s*/g, '');
            } else if (item.name === 'effectiveDate') {
              submitData.month = this.form.LOCAL.effectiveDate.split('/')[0];
              submitData.year = getCardExpiredFullYear(this.form.LOCAL.effectiveDate.split('/')[1]);
            } else if (item.name === 'cardHolderName') {
              submitData.holderName = this.form.LOCAL.cardHolderName.trim();
            } else if (item.name === 'walletAccountName') {
              submitData.holderName = this.form.LOCAL.walletAccountName;
            } else {
              submitData[item.name] = this.form.LOCAL[item.name];
            }
          });
        }
      }
      submitData.country = this.form.CARD.country;
      if (methodType === 'GooglePay') {
        submitData.tokenInfo = {
          provider: 'GooglePay',
          tokenId: payToken,
        };
        submitData.gatewayName = this.gatewayName;
      } else if (methodType === 'ApplePay') {
        submitData.tokenInfo = {
          provider: 'ApplePay',
          tokenId: payToken,
        };
        submitData.gatewayName = this.applePayGatewayName;
      }
      if (submitData.identityNumber) {
        submitData.identityNumber = submitData.identityNumber.trim();
        if (submitData.country === 'BR') {
          submitData.identityNumber = submitData.identityNumber.replace(/[^\d]/g, '');
        }
      }
      try {
        if (!submitData.key) { // key 为空，上报日志
          addLog({
            key: submitData.sessionId || '00000000000000000000000000000000',
            merchantNo: this.order.merchantNo,
            externalOrderId: this.older.merchantTxnId,
            message: '支付参数缺失 key: ' + JSON.stringify(submitData),
          });
          if (submitData.sessionId) {
            submitData.key = submitData.sessionId;
          }
        }
      } catch (err) {
        //
      }
      // DANA_传参
      // if (submitData.cardName === 'DANA_VA') {
      //   submitData.bankName = this.selectedBankCode;
      // }
      if (['DANA_VA', 'DOKU_VA'].includes(submitData.cardName)) {
        submitData.bankName = this.selectedBankCode;
      }
      return submitData;
    },
    async formInlineItemBlurHandler() {
      const name = this.payMethods[this.activePaymentMethod].cardName;
      if (name !== 'CARD') {
        return;
      }
      let message = '';
      await this.$refs.formRef[this.activePaymentMethod].validateField(['effectiveDate', 'cvv'], (error) => {
        message += error;
      });
      if (message) {
        this.marginBottom = '30px';
      } else {
        this.marginBottom = '10px';
      }
    },
    getCardTypeInfos(cards) {
      this.supportCardTypeNames = [];
      this.supportCardTypes = [];
      cards.forEach((item) => {
        // [VISA, MASTERCARD]卡种去重
        if (['VISA COMMERCIAL', 'MASTERCARD COMMERCIAL'].indexOf(item.cardType) < 0 ||
          (item.cardType === 'VISA COMMERCIAL' && this.supportCardTypeNames.indexOf('VISA') < 0) ||
          (item.cardType === 'MASTERCARD COMMERCIAL' && this.supportCardTypeNames.indexOf('MASTERCARD') < 0)) {
          this.supportCardTypeNames.push(item.cardType);
          this.supportCardTypes.push(item);
        }
      });
    },
    addGooglePayScript() {
      const googleScript = document.createElement('script');
      googleScript.src = 'https://pay.google.com/gp/p/js/pay.js';
      googleScript.onload = () => {
        this.googleScriptLoaded = 'success';
      };
      googleScript.onerror = () => {
        this.googleScriptLoaded = 'error';
      };
      document.body.appendChild(googleScript);
    },
    // sendGoogleToken(token) {
    //   const submitData = this.getPaymentData(token);
    //   this.fullscreenLoading = true;
    //   this.requestPayment(submitData);
    // },
    async sendGoogleToken(token) {
      const submitData = this.getPaymentData(token);
      submitData.appId = this.order.appId;
      this.fullscreenLoading = true;
      try {
        const res = await googlePayValidateMerchant(submitData);
        if (res.respCode === '20000') {
          if (!res.data.checkResult && res.data.cardNum) {
            // 需要 cvv 验证
            this.fullscreenLoading = false;
            this.showValidateCvvPopup = true;
            this.validateGooglePayCVVForm = {
              token,
              cardNum: res.data.cardNum,
            };
            return;
          }
        }
      } catch (err) {
        //
      }
      this.requestPayment(submitData);
    },
    onGooglePay(data) {
      this.showValidateCvvPopup = false;
      const submitData = this.getPaymentData(data.token);
      submitData.cardInfo = { cvv: data.cvv };
      this.fullscreenLoading = true;
      this.requestPayment(submitData);
    },

    googlePayUnsupport() {
      if (this.canShowPayReturnAlert) {
        this.showPayReturnAlert = true;
      }
    },
    initGooglePayButton() {
      const transactionInfo = {
        countryCode: this.form.LOCAL.country,
        currencyCode: this.order.txnCurrency,
        totalPriceStatus: 'FINAL',
        // set to cart total
        totalPrice: this.order.txnAmount,
      };
      let allowedCardNetworks = [];
      let website = this.order.website;
      console.log(this.order, 'this.order');
      if (this.order.checkoutModels && this.order && this.order.checkoutModels.length) {
        const googlePayConfig = this.order.checkoutModels
          .find(item => item.cardName === 'GooglePay');

        allowedCardNetworks =
          this.order.checkoutModels
            .find(item => item.cardName === 'GooglePay')
            .cardTypeInfo
            .find(item => item.cardType === 'GooglePay')
            .subCardTypes;
        if (googlePayConfig && googlePayConfig.merchantName) {
          website = googlePayConfig.merchantName;
        }
      }
      if (this.googleScriptLoaded === 'loading') {
        this.googleBtnLoading = true;
        const timer = setInterval(() => {
          if (this.googleScriptLoaded === 'success') {
            clearInterval(timer);
            GooglePay.init(this.googlePayConfig, {
              transactionInfo,
              gatewayName: this.gatewayName,
              gatewayMerchantId: this.googlePayGatewayMerchantId || this.googlePayMerchantId,
              merchantId: this.googlePayMerchantId,
              website,
              language: this.lang,
              merchantNo: this.order.merchantNo,
              externalOrderId: this.order.merchantTxnId,
              subscription: (this.order.subProductType === 'SUBSCRIBE' && this.order.subscription) ? this.order.subscription : '',
              gaData: {
                key: this.key,
                merchant_id: this.order.merchantNo,
                txn_id: this.order.txnId,
                checkout_type: '聚合',
                element_name: this.payMethods[this.activePaymentMethod],
                checkout_order_amount: this.order.txnAmount,
                checkout_order_currency: this.order.txnCurrency,
                checkout_language: this.lang,
                checkout_country: this.form.CARD.country,
              },
            }, `.googleBtnContainer${this.activePaymentMethod}`, this.$refs.formRef[this.activePaymentMethod], this.sendGoogleToken, this.googlePayUnsupport, allowedCardNetworks);
            this.googleBtnLoading = false;
          } else if (this.googleScriptLoaded === 'error') {
            clearInterval(timer);
            showMessage(this.$t('message.result.timeout'));
            this.googleBtnLoading = false;
            // Log
            addLog({
              merchantNo: this.order.merchantNo,
              externalOrderId: this.older.merchantTxnId,
              message: 'GooglePay script load error',
            });
          }
        }, 500);
      } else {
        if (this.googleScriptLoaded === 'success') {
          GooglePay.init(this.googlePayConfig, {
            transactionInfo,
            gatewayName: this.gatewayName,
            gatewayMerchantId: this.googlePayGatewayMerchantId || this.googlePayMerchantId,
            merchantId: this.googlePayMerchantId,
            website,
            language: this.lang,
            merchantNo: this.order.merchantNo,
            externalOrderId: this.order.merchantTxnId,
            subscription: (this.order.subProductType === 'SUBSCRIBE' && this.order.subscription) ? this.order.subscription : '',
            gaData: {
              key: this.key,
              merchant_id: this.order.merchantNo,
              txn_id: this.order.txnId,
              checkout_type: '聚合',
              element_name: this.payMethods[this.activePaymentMethod],
              checkout_order_amount: this.order.txnAmount,
              checkout_order_currency: this.order.txnCurrency,
              checkout_language: this.lang,
              checkout_country: this.form.CARD.country,
            },
          }, `.googleBtnContainer${this.activePaymentMethod}`, this.$refs.formRef[this.activePaymentMethod], this.sendGoogleToken, this.googlePayUnsupport, allowedCardNetworks);
        } else {
          showMessage(this.$t('message.result.timeout'));
          // Log
          addLog({
            merchantNo: this.order.merchantNo,
            externalOrderId: this.older.merchantTxnId,
            message: 'GooglePay script load error',
          });
        }
      }
    },
    addApplePayScript() {
      const applePayScript = document.createElement('script');
      applePayScript.src = 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js';
      applePayScript.onload = () => {
        this.applePayScriptLoaded = 'success';
      };
      /** ***********  ✨ Codeium Command ⭐  *************/
      // Log an error if the Apple Pay script fails to load
      /** ****  9a12fccc-7bf8-4825-88a6-2d3f3981c2f8  *******/
      applePayScript.onerror = () => {
        this.applePayScriptLoaded = 'error';
      };
      document.body.appendChild(applePayScript);
    },
    async initApplePayButton() {
      if (this.applePayBtnShow !== 0) {
        if (this.applePayBtnShow === 2) {
          if (this.canShowPayReturnAlert) {
            this.showPayReturnAlert = true;
          }
        }
        return;
      }
      this.applePayBtnLoading = true;
      if (this.applePayScriptLoaded === 'loading') {
        const timer = setInterval(async () => {
          if (this.applePayScriptLoaded === 'success') {
            clearInterval(timer);
            const appPayEnable = await ApplePay.initButton(this.applePayMerchantIdentifier, this.lang, this.order.merchantNo, this.order.merchantTxnId);
            if (appPayEnable) {
              this.applePayBtnShow = 1;
            } else {
              this.applePayBtnShow = 2;
              if (this.canShowPayReturnAlert) {
                this.showPayReturnAlert = true;
              }
            }
            const timer1 = setTimeout(() => {
              this.applePayBtnLoading = false;
              clearTimeout(timer1);
            }, 1000);
          } else if (this.applePayScriptLoaded === 'error') {
            clearInterval(timer);
            showMessage(this.$t('message.result.timeout'));
            addLog({
              merchantNo: this.order.merchantNo,
              externalOrderId: this.older.merchantTxnId,
              message: 'ApplePay script load error',
            });
            this.applePayBtnShow = 2;
            if (this.canShowPayReturnAlert) {
              this.showPayReturnAlert = true;
            }
            this.applePayBtnLoading = false;
          }
        }, 500);
      } else if (this.applePayScriptLoaded === 'success') {
        const appPayEnable = await ApplePay.initButton(this.applePayMerchantIdentifier, this.lang, this.order.merchantNo, this.order.merchantTxnId);
        if (appPayEnable) {
          this.applePayBtnShow = 1;
        } else {
          this.applePayBtnShow = 2;
          if (this.canShowPayReturnAlert) {
            this.showPayReturnAlert = true;
          }
        }
        const timer1 = setTimeout(() => {
          this.applePayBtnLoading = false;
          clearTimeout(timer1);
        }, 1000);
      } else {
        showMessage(this.$t('message.result.timeout'));
        addLog({
          merchantNo: this.order.merchantNo,
          externalOrderId: this.older.merchantTxnId,
          message: 'ApplePay script load error',
        });
        this.applePayBtnShow = 2;
        if (this.canShowPayReturnAlert) {
          this.showPayReturnAlert = true;
        }
        this.applePayBtnLoading = false;
      }
    },
    startApplePay() {
      this.$refs.formRef[this.activePaymentMethod].validate((valid) => {
        this.sendGaEvent(this.order, 'click_payment_button');
        if (valid) {
          // 设置兜底 防止后端返回卡类型为空的情况
          let supportedNetworks = [];
          if (this.order.checkoutModels && this.order && this.order.checkoutModels.length) {
            supportedNetworks =
              this.order.checkoutModels
                .find(item => item.cardName === 'ApplePay')
                .cardTypeInfo
                .find(item => item.cardType === 'ApplePay')
                .subCardTypes;
          }
          this.fullscreenLoading = true;
          const request = {
            countryCode: this.form.LOCAL.country,
            currencyCode: this.order.txnCurrency,
            // supportedNetworks: ['visa', 'masterCard', 'amex', 'jcb', 'discover'],
            // 需求--修改为动态配置
            supportedNetworks: supportedNetworks && supportedNetworks.length ? supportedNetworks : ['visa', 'masterCard'],
            merchantCapabilities: ['supports3DS'],
            total: { label: this.order.website, type: 'final', amount: this.order.txnAmount },
          };
          if (this.order.subProductType === 'SUBSCRIBE' && this.order.subscription) {
            const subscription = this.order.subscription;
            let managementURL = this.order.website;
            if (!managementURL.startsWith('http')) {
              managementURL = `https://${managementURL}`;
            }
            // 订阅周期支持年  月 周  日
            let recurringPaymentIntervalUnit = 'day';
            switch (subscription.frequencyType) {
            case 'Y':
              recurringPaymentIntervalUnit = 'year';
              break;
            case 'M':
              recurringPaymentIntervalUnit = 'month';
              break;
            case 'W':
              recurringPaymentIntervalUnit = 'week';
              break;
            default:
              recurringPaymentIntervalUnit = 'day';
              break;
            }
            request.recurringPaymentRequest = {
              paymentDescription: 'Recurring payment',
              managementURL,
              regularBilling: {
                label: 'Recurring',
                amount: this.order.txnAmount,
                recurringPaymentIntervalCount: subscription.frequencyPoint,
                recurringPaymentIntervalUnit, // 目前只支持天
                paymentTiming: 'recurring',
                type: 'final',
                recurringPaymentEndDate: subscription.expireDate ? new Date(subscription.expireDate.replace(/-/g, '/')) : null,
              },
            };
          }
          try {
            ApplePay.startPay(request, this.key, this.order.merchantNo, this.order.merchantTxnId, this.applePayComplted, this.closeLoading);
          } catch (err) {
            this.fullscreenLoading = false;
          }
        }
      });
    },
    applePayComplted(token, applePaySession) {
      const submitData = this.getPaymentData(JSON.stringify(token));
      this.requestPayment(submitData, applePaySession);
    },
    closeLoading() {
      this.fullscreenLoading = false;
    },
    clickCountryHandler(evt) {
      if (evt) {
        this.sendGaEvent(this.order, 'click_country_dropdown');
        this.sendRiskDeviceFingerPrint('click_country_dropdown');
      }
    },
    beforeUnloadEvent() {
      this.sendGaEvent(this.order, 'exit_checkout');
      this.sendRiskDeviceFingerPrint('exit_checkout');
    },
    clickSelectHandler(event, type, field) {
      if (event) {
        if (type === 'payment') {
          this.sendGaEvent(this.order, 'focus_payment_info', field);
          this.sendRiskDeviceFingerPrint('focus_payment_info');
        } else {
          this.sendGaEvent(this.order, 'focus_billing_info', field);
        }
      }
    },
    sendPaymentInfoFocusEvent(field) {
      this.sendGaEvent(this.order, 'focus_payment_info', field);
      this.sendRiskDeviceFingerPrint('fill_cardnumber');
      if (field === 'cardNum' && !this.cardNumFocusTime) {
        this.cardNumFocusTime = Date.now();
      }
    },
    sendPaymentInfoInputEvent(field) {
      this.sendGaEvent(this.order, 'input_payment_info', field);
      this.sendRiskDeviceFingerPrint('input_payment_info');
    },
    sendBillingInfoFocusEvent(field) {
      this.sendGaEvent(this.order, 'focus_billing_info', field);
      this.sendRiskDeviceFingerPrint('focus_billing_info');
    },
    sendBillingInfoInputEvent(field) {
      this.sendGaEvent(this.order, 'input_billing_info', field);
      this.sendRiskDeviceFingerPrint('input_billing_info');
    },
    viewPolicy() {
      this.showPolicy = true;
      // gtag
      this.sendGaEvent(this.order, 'click_privacy_policy');
    },
    sendGaEvent(orderInfo, eventName, field = '', extra = {}) {
      try {
        const params = {
          key: this.key,
          merchant_id: orderInfo && orderInfo.merchantNo,
          txn_id: orderInfo && orderInfo.txnId,
          checkout_type: '聚合',
          ...extra,
        };
        // 支付方式
        if (this.payMethods && this.payMethods.length) {
          const currentMethod = this.payMethods[this.activePaymentMethod];
          let methodType = currentMethod.cardName;
          if (currentMethod.children && currentMethod.children.length) {
            const subMethod = currentMethod.children[this.activeChildPaymentMethod];
            methodType = subMethod.cardName;
          }
          params.element_name = methodType;
        }
        // 触发字段
        if (field) {
          params.element_name = field;
        }
        if (['visit_checkout', 'exit_checkout', 'click_payment_button'].includes(eventName)) {
          params.checkout_order_amount = orderInfo && orderInfo.txnAmount;
          params.checkout_order_currency = orderInfo && orderInfo.txnCurrency;
          params.checkout_language = this.lang;
          params.checkout_country = this.form.CARD.country;
        }
        if (eventName === 'visit_checkout' && this.requestingTime) {
          params.api_requesting_time = (this.requestingTime === -1 ? 0 : (Date.now() - this.requestingTime));
          params.visit_checkout_count = 1;
        }
        if (eventName === 'visit_checkout_time' && this.requestingTime) {
          params.time_spent = (this.requestingTime === -1 ? 0 : (Date.now() - this.requestingTime));
        }
        if (eventName === 'click_payment_button' && this.cardNumFocusTime) {
          params.time_spent = Date.now() - this.cardNumFocusTime;
        }
        window.gtag('event', eventName, params);
      } catch (error) { }
    },
    // 返回商户
    onCancel() {
      this.sendGaEvent(this.order, 'cancel_interaction');
      let url = this.order.cancelUrl || this.order.returnUrl;
      if (url) {
        if (url.indexOf('?') > -1) {
          url = `${url}&transactionId=${this.order.txnId}`;
        } else {
          url = `${url}?transactionId=${this.order.txnId}`;
        }
        window.location.href = url;
      }
    },
    queryEmailSearch(queryString, cb) {
      let results = [];
      try {
        const i = queryString.indexOf('@');
        if (i > -1 && i < queryString.length - 1) {
          results = EMAIL_OPTIONS.filter(item => item.value.includes(queryString.substring(i))).map(item => ({ value: queryString.split('@')[0] + item.value }));
        }
      } catch (err) {
        //
      }
      cb(results);
    },
    initCountryList() {
      this.countryList = COUNTRY_OPTIONS.map(item => ({
        ...item,
        label: this.$t(`message.country.${item.code}`),
      })).sort((s1, s2) => {
        try {
          const l = (this.lang === 'zh-tw' || this.lang === 'zh-cn') ? 'zh' : this.lang;
          return s1.label.localeCompare(s2.label, l, { ignorePunctuation: true });
        } catch (err) {
          //
        }
        return 1;
      });
    },
    // 首次进入时 由于此时没有merchantTxnId， 需要传递路由的key
    sendVisitRiskDeviceFingerPrint() {
      const { key } = this.$route.query;
      const param = {
        timeStamp: Date.now(),
        e: 'visit_checkout',
        k: key,
      };
      deviceFingerprintManager.send(param);
    },
    // 发送设备指纹码供风控收集 用户行为数据
    sendRiskDeviceFingerPrint(event_Code, event_Trigger_Reason) {
      const param = {
        m: this.order?.merchantTxnId || '',
        timeStamp: Date.now(),
        e: event_Code,
        r: event_Trigger_Reason || undefined,
      };
      deviceFingerprintManager.send(param);
    },
  },
  watch: {
    lang: function(value) {
      // 更新rules
      for (let i = 0; i < this.payMethods.length; i++) {
        const method = this.payMethods[i];
        let rules = {};
        const methodFields = [];
        if (method.paymentConfigurationFields) {
          methodFields.push(...method.paymentConfigurationFields);
        }
        if (method.billConfigurationFields) {
          methodFields.push(...method.billConfigurationFields);
        }
        if (method.cardName === 'CARD') {
          rules = cardFormRules();
          fieldConfigurationRulesAndNamesHandler(methodFields, rules, this.order.paymentCountry);
          Object.assign(method.rules, rules);
        } else {
          if (method.children && method.children.length) {
            for (let j = 0; j < method.children.length; j++) {
              const subMethod = method.children[j];
              rules = {};
              const subMethodFields = [];
              if (subMethod.paymentConfigurationFields) {
                subMethodFields.push(...subMethod.paymentConfigurationFields);
              }
              if (subMethod.billConfigurationFields) {
                subMethodFields.push(...subMethod.billConfigurationFields);
              }
              fieldConfigurationRulesAndNamesHandler(subMethodFields, rules, this.order.paymentCountry);
              Object.assign(subMethod.rules, rules);
            }
          } else {
            fieldConfigurationRulesAndNamesHandler(methodFields, rules, this.order.paymentCountry);
            Object.assign(method.rules, rules);
          }
        }
      }
      // 更新页面显示的rules
      const activePaymentMethod = this.payMethods[this.activePaymentMethod];
      const activeMethodFields = [];
      if (activePaymentMethod.paymentConfigurationFields) {
        activeMethodFields.push(...activePaymentMethod.paymentConfigurationFields);
      }
      if (activePaymentMethod.billConfigurationFields) {
        activeMethodFields.push(...activePaymentMethod.billConfigurationFields);
      }
      if (activePaymentMethod.cardName === 'CARD') {
        const cardFields = ['periodValue'];
        cardFields.forEach((item) => {
          const ref = this.$refs[`${item}RefCard`] && this.$refs[`${item}RefCard`][0];
          if (ref && ref.$el.className.indexOf('is-error') > -1) {
            this.$refs.formRef[this.activePaymentMethod].validateField(item);
          }
        });
        activeMethodFields.forEach((item) => {
          const ref = this.$refs[`${item.name}RefCard`] && this.$refs[`${item.name}RefCard`][0];
          if (ref && ref.$el.className.indexOf('is-error') > -1) {
            this.$refs.formRef[this.activePaymentMethod].validateField(item.name);
          }
        });
      } else {
        if (activePaymentMethod.children && activePaymentMethod.children.length) {
          const realActivePaymentMethod = activePaymentMethod.children[this.activeChildPaymentMethod];
          const activeSubMethodFields = [];
          if (realActivePaymentMethod.paymentConfigurationFields) {
            activeSubMethodFields.push(...realActivePaymentMethod.paymentConfigurationFields);
          }
          if (realActivePaymentMethod.billConfigurationFields) {
            activeSubMethodFields.push(...realActivePaymentMethod.billConfigurationFields);
          }
          activeSubMethodFields.forEach((item, idx) => {
            const ref = this.$refs[`${item.name}Ref${this.activeChildPaymentMethod}`] && this.$refs[`${item.name}Ref${this.activeChildPaymentMethod}`][0];
            if (ref && ref.$el.className.indexOf('is-error') > -1) {
              this.$refs.formRef[this.activePaymentMethod].validateField(item.name);
            }
          });
        } else {
          activeMethodFields.forEach((item, idx) => {
            const ref = this.$refs.formRef[this.activePaymentMethod].fields[idx];
            if (ref && ref.$el.className.indexOf('is-error') > -1) {
              this.$refs.formRef[this.activePaymentMethod].validateField(item.name);
            } else {
            }
          });
        }
      }

      this.initCountryList();
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/checkout.scss';
</style>
<style lang="scss" scoped>
@import '@/styles/element-variables.scss';

$color-payment-method-border: #CCD0D4;
$color-card-border: #979797;

.order-box {
  .method-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .payments-support {
      display: flex;
      align-items: center;
      font-size: 20px;
      overflow: hidden;
      line-height: 20px;
      float: right \9;

      .img {
        margin-right: 5px;
        width: auto;

        &:last-child {
          margin-right: 0;
        }
      }

      .card-number {
        color: #99A3AD;
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  &.payment-content {
    .fields-row {
      display: flex;
      flex-wrap: wrap;
    }

    // payment method部分样式
    .payment-method-wrapper {
      padding: 30px 20px 60px;

      ::v-deep .el-collapse {
        border: 0;

        .method-collapse-item {
          padding: 0px;
          transition: background-color .5s;
          border-top: 1px solid $color-payment-method-border;
          border-left: 1px solid $color-payment-method-border;
          border-right: 1px solid $color-payment-method-border;

          &.is-active {
            background-color: #FFF;
            margin-top: 20px;
            margin-bottom: 20px;
            border: 1px solid $color-payment-method-border;
            border-radius: 10px;
          }

          &:first-child {
            margin-top: 0;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }

          &:last-child {
            margin-bottom: 0;
            border-bottom: 1px solid $color-payment-method-border;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }

          &.before {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-bottom: 1px solid $color-payment-method-border;
          }

          &.after {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }

          .el-collapse-item__header {
            background-color: transparent;
            border: 0;
            cursor: auto;
            padding: 14px 10px 3px;
            min-height: 54px;
            height: auto;
            height: 54px \9;
            display: flex;
            align-items: flex-start;
            box-sizing: border-box;

            &.is-active {
              .payments-support {}
            }
          }

          .el-collapse-item__wrap {
            background-color: transparent;
            border: 0;

            .el-collapse-item__content {
              padding-bottom: 20px;
            }
          }

          .card-type-radio {
            display: flex;
            align-items: center;

            .el-radio__input {
              vertical-align: top;

              .el-radio__inner {
                transform: scale(1.143);
              }
            }

            .el-radio__label {
              height: 26px;
              display: inline-block;
            }
          }

          .card-name {
            display: inline-flex;
            align-items: center;
            color: $color-text;

            .card-img-wrapper {
              display: inline-block;
              margin-right: 6px;
              border-radius: 2px;
              position: relative;
              width: 50px;
              height: 26px;
              box-sizing: border-box;
              vertical-align: top;

              &::after {
                content: '';
                position: absolute;
                width: 335%;
                height: 332%;
                top: 0;
                left: 0;
                transform-origin: 0 0;
                border: 1px solid $color-card-border;
                transform: scale(0.3);
                border-radius: 2px;
                box-sizing: border-box;
                pointer-events: none;
              }

              img {
                max-width: 42px;
                max-height: 18px;
                display: block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }

            .card-type-name {
              line-height: 26px;
            }
          }
        }

        .el-collapse-item__arrow {
          &::before {
            display: none;
          }
        }
      }

      // 账单样式
      .billing-title {
        font-weight: 500;
        font-size: 16px;
        color: $color-text;
        line-height: 20px;
        padding-top: 10px;
        padding-bottom: 0px;
        box-sizing: border-box;
        // border-top: 1px solid $color-border-light;
        // border-bottom: 1x solid $color-border-light;
      }

      .billing-row {
        display: inline-flex;
        display: -ms-flex;
        flex-wrap: wrap;
        width: 100%;
      }

      .sub-payment-method {
        padding: 0 36px 0px;

        ::v-deep(.el-radio__inner) {
          transform: scale(1.143);
        }

        ::v-deep(.sub-method-collapse-item) {

          &:first-child {
            margin-top: 10px;
          }

          .el-collapse-item__header {
            padding: 0;
            height: 26px;
            min-height: initial;
            margin-bottom: 10px;
          }

          &:last-child {
            .el-collapse-item__content {
              padding-bottom: 0 !important;
            }
          }

          .sub-method-radio {
            display: block;
            height: 26px;
            line-height: 26px;
          }

          .sub-method-title {
            display: flex;
            align-items: center;
          }

          .sub-method-img-wrapper {
            display: inline-block;
            margin-right: 6px;
            border-radius: 2px;
            position: relative;
            width: 50px;
            height: 26px;
            box-sizing: border-box;
            vertical-align: top;

            &::after {
              content: '';
              position: absolute;
              width: 335%;
              height: 332%;
              top: 0;
              left: 0;
              transform-origin: 0 0;
              border: 1px solid $color-card-border;
              transform: scale(0.3);
              border-radius: 2px;
              box-sizing: border-box;
              pointer-events: none;
            }

            img {
              max-width: 42px;
              max-height: 18px;
              display: block;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .sub-method-name {
            color: $color-text;
          }

          .billing-title {
            background-color: transparent;
            padding: 0;
            margin-top: 10px;
            border: 0;
            font-weight: 800;
          }
        }
      }
    }
  }

  .btns {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;

    .btn-submit {
      width: 100%;

      &.disabled-tip {
        background-color: #B3B3B3 !important;
        color: #FFF !important;
        border-color: #B3B3B3 !important;
      }
    }
  }
}

.btn-cancel-box {
  text-align: center;
  margin-bottom: 20px;

  &.mobile {
    display: none;
  }
}

.country-selector {
  ::v-deep &.el-select {
    min-width: 315px;

    .el-input__prefix {
      display: inline-block;
      position: relative;
      box-sizing: border-box;
      padding-left: 15px;
      padding-right: 90px;
      height: 40px;
      line-height: 40px;
      color: #27272B;
      left: 0;
      visibility: hidden;
      min-width: 315px;
    }

    .el-input.el-input--prefix.el-input--suffix {
      height: 40px;
    }

    .el-input--prefix {
      height: 40px;

      .el-input__inner {
        position: absolute;
        padding-left: 15px;
      }
    }
  }
}

.cvv {
  ::v-deep &.el-input--suffix {
    .el-input__inner {
      padding-right: 60px;
    }
  }
}

@media screen and (max-width: 1140px) {
  .checkout-wrapper {
    .order-box {
      border: 0;

      &.payment-content {
        &>.title {
          display: none;
        }

        .method-title {
          .payments-support {
            img {
              margin-right: 5px;
            }
          }
        }

        .payment-method-wrapper {
          padding: 0;

          .billing-title {
            text-align: center;
          }

          .sub-payment-method {
            padding: 0 20px;
          }
        }
      }

      &.product-content {
        width: 100%;
        margin-left: 0;

        .mobile-padding {
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      .btns {
        // padding-left: 5px;
        // padding-right: 5px;
      }
    }

    .btn-cancel-box {
      display: none;

      &.mobile {
        display: block;

        .btn-cancel {
          display: inline;
        }
      }
    }

    .country-selector {
      ::v-deep &.el-select {
        width: 315px;

        .el-input__prefix {
          display: none;
          min-width: auto;
        }

        .el-input__inner {
          position: relative;
          padding-left: 15px;
        }
      }

      input {
        font-weight: bold;
      }
    }
  }
}

.DANA_wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;

  .DANA_item {
    cursor: pointer;
    flex: 0 0 calc(25% - 20px);
    height: 50px;
    border: 1px solid $color-payment-method-border;
    border-radius: 14px;
    padding: 20px 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80px;
    }
  }

  .DANA_active {
    border-color: #0098d8;
    border-width: 2px;
  }
}
</style>
